import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";


import { FC, useState } from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { MUIButton } from "../../atoms/Button/Button";
import { TabPanel } from "../../atoms/TabPanel";
import { BasicCondition } from "./FormTabContainer/basicCondition";
import { FormHeaderSection } from "./FormHeader/FormHeaderSection";
import { SpecificCondition } from "./FormTabContainer/specificCondition";
import { LastMonthMenuCondition } from "./FormTabContainer/lastMonthMenuCondition";
import { FixedDayCondition } from "./FormTabContainer/fixedDayCondition";
import { MenuListType } from "../../../types/menus";
import { useLocation } from 'react-router-dom';

type Props = {
  isPresetConditions: boolean,
  formMethods: UseFormReturn<any>,
  onSubmit: () => void,
  lastMonthMenu: MenuListType[],
}

export const ConditionForm: FC<Props> = (({isPresetConditions, formMethods, onSubmit, lastMonthMenu}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const location = useLocation();
  const pathIncludesPresetConditions = location.pathname.includes('preset_conditions');
  return (
  <FormProvider {...formMethods}>
    <form onSubmit={formMethods.handleSubmit(() => onSubmit)}>
      <FormHeaderSection isPresetConditions={isPresetConditions} />

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        {!pathIncludesPresetConditions ? (
          <Tabs
            value={tabIndex}
            onChange={(event: React.SyntheticEvent, newValue: number) => {
              setTabIndex(newValue);
            }}
            variant="fullWidth"
          >
            <Tab label="基本項目" />
            <Tab label="制約条件" />
            <Tab label="先月末献立指定" />
            <Tab label="納豆・洋食固定曜日指定" />
          </Tabs>
          ) : (
            <Tabs
            value={tabIndex}
            onChange={(event: React.SyntheticEvent, newValue: number) => {
              setTabIndex(newValue);
            }}
            variant="fullWidth"
          >
            <Tab label="基本項目" />
            <Tab label="制約条件" />
          </Tabs>
          )}
      </Box>

      <Grid sx={{ marginY: (theme) => theme.spacing(3),}}>
        <TabPanel value={tabIndex} index={0}>
          <BasicCondition />
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <SpecificCondition />
        </TabPanel>
        <TabPanel value={tabIndex} index={2}>
          <LastMonthMenuCondition lastMonthMenu={lastMonthMenu}/>
        </TabPanel>
        <TabPanel value={tabIndex} index={3}>
          <FixedDayCondition />
        </TabPanel>
      </Grid>
      <Grid sx={{ display: "flex", justifyContent: "flex-end",}}>
        <MUIButton
          onPush={formMethods.handleSubmit(onSubmit)}
          text={"条件を保存する"}
        />
      </Grid>
    </form>
  </FormProvider>);
});