import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useFormContext } from "react-hook-form"
import { ControlTextField } from "../../../atoms/ControlTextField/ControlTextField"
import { MUIButton } from "../../../atoms/Button/Button";
import { ControlSelect } from "../../../atoms/ControlSelect/ControlSelect";

type SearchFormProps = {
  handleSubmit: () => void
}

export const SearchForm = (props: SearchFormProps) => {
  const { handleSubmit } = props;
  const {control, trigger} = useFormContext();

  const typeOptions = [
    {title: '選択する', value: ''},
    {title: '主菜', value: '主菜' },
    {title: '朝食向け主菜	', value: '朝食向け主菜' },
    {title: '副菜', value: '副菜' },
    {title: '添え物	', value: '添え物' },
    {title: '丼・替りご飯', value: '丼・替りご飯' },
    {title: '汁物', value: '汁物' },
    {title: 'おやつ', value: 'おやつ' },
    {title: 'パン', value: 'パン' },
    {title: '米', value: '米' },
    {title: '飲料、乳製品', value: '飲料、乳製品' },
    {title: '彩膳主菜(朝食）', value: '彩膳主菜(朝食）' },
  ];
  const categoryOptions = [
    {title: '選択する', value: ''},
    {title: '和', value: '和'},
    {title: '中', value: '中' },
    {title: '洋', value: '洋' },
  ];

  return (
    <Grid
      container
      sx={{
        paddingTop: (theme) => theme.spacing(5),
        paddingX: (theme) => theme.spacing(3),
      }}
    >
      <Box sx={{ minWidth: 250}}>
        <ControlTextField 
          type="text"
          name="source_id"
          label="商品コード"
          control={control}
          trigger={trigger}
        />
      </Box>
      <Box sx={{ minWidth: 250, marginLeft: 3}}>
        <ControlTextField 
          type="text"
          name="name"
          label="商品名"
          control={control}
          trigger={trigger}
        />
      </Box>
      <Box sx={{ minWidth: 200, marginLeft: 3 }}>
        <ControlSelect
          name="type"
          options={typeOptions}
          control={control}
          label="分類"
          defaultValue={0}
        />
      </Box>
      <Box sx={{ minWidth: 200, marginLeft: 3 }}>
        <ControlSelect
          name="category"
          options={categoryOptions}
          control={control}
          label="カテゴリ"
          defaultValue={""}
        />
      </Box>

      <Box sx={{ minWidth: 250, marginLeft: 3, paddingTop: 1 }}>
        <MUIButton        
          onPush={handleSubmit}
          text={'検索する'}
        />
      </Box>
    </Grid>
  )
}