import { AxiosError } from "axios";
import React, { useCallback, useEffect, useState } from "react";
import api from "../../const/api";
import { APIPathGetMenusByMembershipId } from "../../endpoint";
import { MenuListType } from "../../types/menus";
import { useContextData } from "../../context/ContextWrapper";
import firebase from "firebase/compat/app";
import {useSnackbar} from "../../components/organisms/SnackProvider";

export const useMenuList = (membershipTypeId:  string | undefined) => {
  const {targetMonth, setTargetMonth} = useContextData()
  const [menuVariationList, setMenuVariationList] = useState([] as MenuListType[]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<AxiosError | null>(null);
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user === null) return;
      setToken(await user.getIdToken(false));
    });
  })

  // TODO:: usefetchをより抽象化して使いやすくする or swrを入れる
  // handleClickが押されてMonthが変更 → fetchすることで初めてページが送られる作りなためuseEffectの副作用を使用していない
  const handleChangePage = useCallback(() => {
    if (token === null) return;
    try {
      api.get(APIPathGetMenusByMembershipId(membershipTypeId, targetMonth), token).then(
        (result) => {
          setMenuVariationList(result.data);
        }
      )
    } catch (e: unknown) {
      setError(error as AxiosError);
    } finally {
      setIsLoading(false);
    }
  },[membershipTypeId, targetMonth, token]);  // eslint-disable-line
  
  const handleClickPrevious = (event: React.MouseEvent<HTMLButtonElement>) => {
    targetMonth.setMonth(targetMonth.getMonth() -1);
    setTargetMonth(targetMonth);
    handleChangePage();
  };

  const handleClickNext = (event: React.MouseEvent<HTMLButtonElement>) => {
    targetMonth.setMonth(targetMonth.getMonth() +1);
    setTargetMonth(targetMonth);
    handleChangePage();
  };

  useEffect(() => {
    handleChangePage();
  }, [handleChangePage, token]);

  return {
    isLoading,
    error,
    menuVariationList,
    targetMonth,
    handleClickPrevious,
    handleClickNext,
  }
};
