import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { FC, memo } from "react";
import { FormProvider } from "react-hook-form";
import { useParams, useSearchParams } from "react-router-dom";
import { ManualContent } from "../../components/organisms/ManualDishes/ManualContent";
import { useGetManualMeal } from "./useGetManual.hooks";
import { usePostManual } from "./usePostManual.hooks";
import { useSelectDish } from "./useSelectDish.hooks";
import { MealType, mealTypeTitle } from "../../types/menu";
import { changeStrToDateFormat } from "../../util/date";
import { assertIsDefined } from "../../util/assertIsDefined";
import { LoadingTemplate } from "../../components/template/LoadingTemplate/LoadingTemplate";
import { useSnackbar } from "../../components/organisms/SnackProvider";
import { MUIButton } from "../../components/atoms/Button/Button";
import { SelectIncrementalSearch } from "../../components/molecules/SelectIncrementSearch/SelectIncrementSearch";
import { Alert, Typography } from '@mui/material';
import { PageHeader } from "../../components/molecules/PageHeader/PageHeader";

type Params = { 
  menuId: string, 
  targetDay: string, // YYYY-MM-DD
  mealType: MealType 
}

/**
 * 献立指定画面
 */
export const ManualDishesPage: FC = memo(() => {
  const { showSnackbar } =  useSnackbar();
  const { menuId, targetDay, mealType } = useParams<Params>();
  const [searchParams] = useSearchParams();
  //
  // manual mealのIDをbackendに対して検索し、404の場合は追加 (HTTP PUT)、存在する場合は編集 (HTTP POST) をする。
  //
  // TODO: 指定がないときの扱いをマシにする
  // 現状の実装ではID指定が出来ない（指定された献立がない）場合でも一度クエリを叩いて404を受けた後、manualMealがnullという形で処理が続く
  const manualId = searchParams.get('id') ?? ''

  assertIsDefined(menuId);
  assertIsDefined(targetDay);
  assertIsDefined(mealType);

  // APIサーバに対して登録済の手動指定の献立 (Manual Meal) が無いかを調べる。あればそれを編集するし、なければ新規に作ることになる
  const { editingDishes, manualMeal, error, isLoading: isLoadingManualMeal,
          selectErr, addSingleDish, handleRemove } = useGetManualMeal(menuId, manualId);
  // 検索インターフェースのハンドラ一式を準備する
  const { options, isSearching, selectedVal, handleSearch, handleSelect } = useSelectDish(addSingleDish);
  // 新規登録・更新のためのハンドラ一式を準備する
  const { formMethods, handleSubmit,
          isLoading, handleDelete, isLoadingDelete } = usePostManual(menuId, editingDishes, manualMeal, targetDay, mealType);

  if (error) {
    showSnackbar(`取得時にエラーが発生しました。: ${error}`, 'error')
  }
  if (isLoadingManualMeal || isLoading || isLoadingDelete) {
    return <LoadingTemplate />
  }

  return (
    <>
      <PageHeader title={`献立指定画面 : ${changeStrToDateFormat(targetDay ?? '', 'YYYY年MM月DD日', true)}　${mealTypeTitle[mealType as MealType]}`}/>
      <Typography variant="body2" marginTop={1}>
        {"全ての献立指定条件の追加後、前のページから「献立を生成する」を再度実行してください"}
      </Typography>
      <Typography variant="body2" marginTop={1}>
        {"指定条件を考慮した献立と画像が生成されます。"}
      </Typography>

      <Grid sx={{ marginY: (theme) => theme.spacing(5), rowGap: (theme) => theme.spacing(3)}}>
        <Grid display={"flex"}>
          <Grid width={400} >
            <SelectIncrementalSearch 
              options={options} 
              handleSearch={handleSearch} 
              handleSelect={handleSelect} 
              selectedVal={selectedVal}
              isLoading={isSearching}
              width={450} 
              placeholder="指定したい商品名またはコードを入力してください"
            />
            { selectErr && ((
              <Box sx={{ marginTop: 2 }}>
                <Alert severity="error">同じ商品は選択できません</Alert>
              </Box>
            ))}
          </Grid>
          <Grid container sx={{ display: "flex", justifyContent: "right" }}>
            {manualMeal &&  <MUIButton onPush={handleDelete} text={"献立指定を削除する"} color="error"/>}
          </Grid>
        </Grid>

        <Grid marginTop={6}> 
          <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(() => {})}>
              { editingDishes && editingDishes.length > 0 ? (
                <ManualContent manualDishes={editingDishes} handleRemove={handleRemove} handleSubmit={handleSubmit}/>
              ):( <Box>献立が指定されていない状態です</Box> ) 
              }
            </form>
          </FormProvider>
        </Grid>
      </Grid>
    </>
  );
});
