import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { createContext, useState, useEffect, ReactNode, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { auth_app } from "../../../lib/IdentityPlatform/index";

export type UserType = User | null;

/**
 * isLoadingUser ... ユーザ情報を取得中の場合true
 * user ... ユーザ情報。情報を取得中、もしくはユーザ情報がない（≒ログインしていない）場合はnull
 * token ... Bearerトークン。情報を取得中、もしくはユーザ情報がない場合はnull
 */
export type AuthContextProps = {
  isLoadingUser: Boolean;
  user: UserType;
  token: string | null;
};

export type AuthProps = {
  children: ReactNode;
};

export const AuthContext = createContext<Partial<AuthContextProps>>({});

export const useAuthContext = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }: AuthProps) => {
  const auth = getAuth(auth_app);
  const navigate = useNavigate();
  const [isLoadingUser, setLoadingUser] = useState<boolean>(true)
  const [user, setUser] = useState<UserType>(null);
  const [token, setToken] = useState<string | null>(null);

  const value = {
    isLoadingUser,
    user,
    token,
  };

  useEffect(() => {
    const authStateChanged = onAuthStateChanged(auth, async (user) => {
      setLoadingUser(false);
      setUser(user);

      if (user === null) {
        navigate("/login");
        return;
      }
      setToken(await user.getIdToken());
    });
    return () => {
      authStateChanged();
    };
  }, [auth, navigate]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
