import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { FC, memo, ReactElement } from "react";

type Props = {
  title: string;
  children: ReactElement;
};

export const SideBarSection: FC<Props> = memo(({ title, children }) => {
  return (
    <>
      <Typography color="gray">{title}</Typography>
      {children}
      <Divider />
    </>
  );
});
