import { createContext, useCallback, useState } from "react";
type SideBarContext = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const defaultContext: SideBarContext = {
  isOpen: false,
  setIsOpen: () => {},
};

export const sideBarContext = createContext<SideBarContext>(defaultContext);

// custom Hook
export const useSideBarOpen = (): SideBarContext => {
  const [isOpen, setOpen] = useState(false);

  const setIsOpen = useCallback((current: boolean): void => {
    setOpen(current);
  }, []);

  return {
    isOpen,
    setIsOpen,
  };
};
