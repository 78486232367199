import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { FC, memo } from "react";
import { MenuVariationColumn } from "../../../../const/tableColumns";
import { TableHeader } from "../../../atoms/tableHeader";
import { TableWrapper } from "../../../atoms/tableWrapper";
import { MenuListType } from "../../../../types/menus";
import { MenuVariationsActionCell } from "./menuVariationsActionCell";

type Props = {
  membershipTypeId: string;
  dataList: MenuListType[]
}


export const MenuListTable: FC<Props> = memo(({membershipTypeId, dataList}) => {
  return (
    <TableWrapper>
      <>
        <TableHeader headCells={MenuVariationColumn} />

        <TableBody>
          {dataList.map((menuItem, idx: number) => (
            <TableRow key={idx}>
              <TableCell
                variant="head"
                padding={"normal"}
                sx={{
                  backgroundColor: (theme) => theme.palette.secondary.main,
                  color: (theme) => theme.palette.secondary.contrastText,
                  textAlign: "center",
                }}
              >
                {menuItem.id}
              </TableCell>
              <TableCell
                variant="head"
                padding={"normal"}
                sx={{
                  backgroundColor: (theme) => theme.palette.secondary.main,
                  color: (theme) => theme.palette.secondary.contrastText,
                  textAlign: "center",
                }}
              >
                {menuItem.name}
              </TableCell>

              {/* アクションボタン */}
              <MenuVariationsActionCell membershipTypeId={membershipTypeId} menuId={menuItem.id} />
            </TableRow>
          ))}
        </TableBody>

        <TableFooter>
          <TableRow />
        </TableFooter>
      </>
    </TableWrapper>
  );
});
