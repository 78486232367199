import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { PriceType } from "../../dataModel/priceType/PriceType";
import { APIPathPostCreatePresetConditions, APIPathGetPresetConditionsList } from "../../endpoint";
import { usePost } from "../../hooks/usePost";
import { ConditionSchema } from "../../lib/Validation/yup/conditionSchema";
import { ConditionFormValues, PostPresetConditionsVal } from "../../types/condition";
import { useSnackbar } from "../../components/organisms/SnackProvider";

export const usePresetConditionsForm = (() => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  const initialValues: ConditionFormValues = {
    id: "",
    presetConditionName: "",
    presetConditionCode: "",
    priceType: PriceType.price_a.value,
    breakfastMinPrice: 0,
    breakfastMaxPrice: 168,
    lunchMinPrice: 0,
    lunchMaxPrice: 255,
    dinnerMinPrice: 0,
    dinnerMaxPrice: 255,
    dailyMinEnergy: 405,
    dailyMaxEnergy: 540,
    dailyMinProtein: 20,
    dailyMaxProtein: 32,
    dailyMinSalt: 1,
    dailyMaxSalt: 5.4,
    dailyMinPotassium: 300,
    dailyMaxPotassium: 1800,
    dailyMinPhosphorus: 60,
    dailyMaxPhosphorus: 360,
    eachBreakfastMinEnergy: 135,
    eachBreakfastMaxEnergy: 180,
    eachLunchMinEnergy: 135,
    eachLunchMaxEnergy: 180,
    eachDinnerMinEnergy: 135,
    eachDinnerMaxEnergy: 180,
    eachBreakfastMinProtein: 7,
    eachBreakfastMaxProtein: 11,
    eachLunchMinProtein: 7,
    eachLunchMaxProtein: 11,
    eachDinnerMinProtein: 7,
    eachDinnerMaxProtein: 11,
    eachBreakfastMinSalt: 0.3,
    eachBreakfastMaxSalt: 1.8,
    eachLunchMinSalt: 0.3,
    eachLunchMaxSalt: 1.8,
    eachDinnerMinSalt: 0.3,
    eachDinnerMaxSalt: 1.8,
    eachBreakfastMinPotassium: 100,
    eachBreakfastMaxPotassium: 600,
    eachLunchMinPotassium: 100,
    eachLunchMaxPotassium: 600,
    eachDinnerMinPotassium: 100,
    eachDinnerMaxPotassium: 600,
    eachBreakfastMinPhosphorus: 20,
    eachBreakfastMaxPhosphorus: 120,
    eachLunchMinPhosphorus: 20,
    eachLunchMaxPhosphorus: 120,
    eachDinnerMinPhosphorus: 20,
    eachDinnerMaxPhosphorus: 120,
    optionSpecificConditions: {},
    lastMonthMenuJobId: "",
    nattoKoteiYoubi: "",
    yoshokuKoteiYoubi1: "",
    yoshokuKoteiYoubi2: "",
  };

  const formMethods = useForm<ConditionFormValues>({
    mode: "onChange",
    defaultValues: initialValues,
    resolver: yupResolver(ConditionSchema),
  });

  const [ doPost ] = usePost({
    method: 'post',
    url: APIPathPostCreatePresetConditions,
  });

  const handleCreate = useCallback(() => {
    const values = formMethods.getValues();
    const postData: PostPresetConditionsVal = {
      name: values.presetConditionName,
      code: values.presetConditionCode,
      price_type: values.priceType,
      breakfast_price_min: values.breakfastMinPrice,
      breakfast_price_max: values.breakfastMaxPrice,
      lunch_price_min: values.lunchMinPrice,
      lunch_price_max: values.lunchMaxPrice,
      dinner_price_min: values.dinnerMinPrice,
      dinner_price_max: values.dinnerMaxPrice,
      energy_min: values.dailyMinEnergy,
      energy_max: values.dailyMaxEnergy,
      // 入力項目がないので、固定値で渡す
      // https://jdsc.slack.com/archives/C0395V6U353/p1674449885316459
      fat_min: 0,
      fat_max: 1,
      carb_min: 0,
      carb_max: 1,
      protein_min: values.dailyMinProtein,
      protein_max: values.dailyMaxProtein,
      salt_min: values.dailyMinSalt,
      salt_max: values.dailyMaxSalt,
      potassium_min: values.dailyMinPotassium,
      potassium_max: values.dailyMaxPotassium,
      phosphorus_min: values.dailyMinPhosphorus,
      phosphorus_max: values.dailyMaxPhosphorus,
      energy_breakfast_min: values.dailyMinEnergy,
      energy_breakfast_max: values.dailyMaxEnergy,
      protein_breakfast_min: values.dailyMinProtein,
      protein_breakfast_max: values.dailyMaxProtein,
      salt_breakfast_min: values.dailyMinSalt,
      salt_breakfast_max: values.dailyMaxSalt,
      potassium_breakfast_min: values.eachBreakfastMinPotassium,
      potassium_breakfast_max: values.eachBreakfastMaxPotassium,
      phosphorus_breakfast_min: values.eachBreakfastMinPhosphorus,
      phosphorus_breakfast_max: values.eachBreakfastMaxPhosphorus,
      energy_lunch_min: values.dailyMinEnergy,
      energy_lunch_max: values.dailyMaxEnergy,
      protein_lunch_min: values.dailyMinProtein,
      protein_lunch_max: values.dailyMaxProtein,
      salt_lunch_min: values.dailyMinSalt,
      salt_lunch_max: values.dailyMaxSalt,
      potassium_lunch_min: values.eachLunchMinPotassium,
      potassium_lunch_max: values.eachLunchMaxPotassium,
      phosphorus_lunch_min: values.eachLunchMinPhosphorus,
      phosphorus_lunch_max: values.eachLunchMaxPhosphorus,
      energy_dinner_min: values.dailyMinEnergy,
      energy_dinner_max: values.dailyMaxEnergy,
      protein_dinner_min: values.dailyMinProtein,
      protein_dinner_max: values.dailyMaxProtein,
      salt_dinner_min: values.dailyMinSalt,
      salt_dinner_max: values.dailyMaxSalt,
      potassium_dinner_min: values.eachDinnerMinPotassium,
      potassium_dinner_max: values.eachDinnerMaxPotassium,
      phosphorus_dinner_min: values.eachDinnerMinPhosphorus,
      phosphorus_dinner_max: values.eachDinnerMaxPhosphorus,
      option_specific_conditions: values.optionSpecificConditions,
      last_month_menu_job_id: values.lastMonthMenuJobId,
      natto_kotei_youbi: values.nattoKoteiYoubi,
      yoshoku_kotei_youbi_1: values.yoshokuKoteiYoubi1,
      yoshoku_kotei_youbi_2: values.yoshokuKoteiYoubi2,
    };

    doPost({
      params: postData,
      onSuccess: () => {
        navigate(APIPathGetPresetConditionsList);
        showSnackbar(`プリセット制約条件を作成しました code: ${postData.code}`, 'success');
      },
      onError: (err) => {
        // TODO: プリセット条件コード削除後にこのコードを削除する
        if (err.response?.status === 400) {
          const detail = (err.response?.data as any).detail;
          showSnackbar(`更新に失敗しました: ${detail}`, "error");
        } else {
          showSnackbar(`更新に失敗しました: ${err.message}`, "error");
        }
      }
    });
  }, [doPost, navigate, showSnackbar, formMethods]);

  return {
    formMethods,
    handleCreate,
  };
});