import {useCallback, useEffect, useMemo, useState} from "react";
import {APIGetManualMenuById} from "../../endpoint";
import {ManualMeal, ManualMealDish} from "../../types/menuManual";
import {useFetch} from "../../hooks/useFetch";


export const useGetManualMeal = (menuId: string, manualId: string) => {
  const {data: manualMeal, error, isLoading} = useFetch<ManualMeal>({
    url: APIGetManualMenuById(menuId, manualId)
  });
  const [editingDishes, setEditingDishes] = useState<ManualMealDish[] | null>(null);

  useEffect(() => {
    if (isLoading) return;
    if (manualMeal && manualMeal.dishes) {
      setEditingDishes(manualMeal.dishes)
    } else {
      setEditingDishes([])
    }
  }, [isLoading, manualMeal])

  const [selectErr, setSelectErr] = useState('');

  // 編集中の商品一覧のうちのuid一つを受け取ってそれを登録済一覧から削除する
  // 商品一覧のコンポーネントに登録される
  const handleRemove = useCallback((uid: string) => {
    if (editingDishes === null || editingDishes === undefined) return;

    setEditingDishes(editingDishes.filter((val: ManualMealDish) => val.source_uid !== uid));
  }, [editingDishes]);

  // 検索インターフェースにおいて選択された商品を新たに編集中の商品として追加する
  // 検索インターフェースのハンドラとして登録される
  const addSingleDish = useCallback((val: ManualMealDish) => {
    if (editingDishes === null || editingDishes === undefined) return;

    setSelectErr('');
    if (editingDishes.find((v :ManualMealDish) =>  v.source_uid === val.source_uid)) {
      setSelectErr('同じ商品は選択できません');
      return;
    }
    setEditingDishes([...editingDishes, val]);
  }, [editingDishes]);

  return {
    editingDishes,
    manualMeal,
    isLoading: editingDishes === null,
    error,
    selectErr,
    addSingleDish,
    handleRemove,
  }
}
