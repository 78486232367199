import { FC, useContext, useState } from "react"
import DateContext, { GlobalContextType } from "./GlobalContext"

type Props = {
  children: React.ReactNode
}

export const ContextWrapper: FC<Props> = ({
  children 
}) => {
  const [targetMonth, setTargetMonth] = useState<Date>(new Date())

  return (
    <DateContext.Provider
      value={{
        targetMonth,
        setTargetMonth
      }}
    >
      {children}
    </DateContext.Provider>    
    )
}

export const useContextData = (): GlobalContextType => {
  const context = useContext(DateContext)
  if (context === undefined) {
    throw new Error('useContextData must be used within a ContextWrapper')
  }
  
  return context
}