import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { FC, memo, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { sideBarContext } from "../../template/AppTemplate/state";

type Props = {
  text: string;
  url: string;
};

export const SideBarItem: FC<Props> = memo(({ text, url }) => {
  const ctx = useContext(sideBarContext);
  const navigate = useNavigate();

  const push = (url: string) => {
    ctx.setIsOpen(false);
    navigate(url);
  };

  return (
    <ListItem button={true} onClick={() => push(url)}>
      <ListItemText primary={text} />
    </ListItem>
  );
});
