import { FC, memo } from "react";
import { useParams } from "react-router-dom";
import { usePresetConditionsForm } from "./usePresetConditionForm.hooks";
import { ConditionForm } from "../../components/organisms/ConditionForm";
import { useLastMonthMenuList } from "./useLastMonthMenu.hooks";
import { PageHeader } from "../../components/molecules/PageHeader/PageHeader";
import { LoadingTemplate } from "../../components/template/LoadingTemplate/LoadingTemplate";


export const EditPresetConditionsPage: FC = memo(() => {
  const { presetConditionsId } = useParams();
  const { isLoading,formMethods, handleUpdate } = usePresetConditionsForm(presetConditionsId);
  const { menuVariationList, } = useLastMonthMenuList();

  if (isLoading) return <LoadingTemplate />;

  return (
    <>
      <PageHeader title={"プリセット条件編集"} />
      <ConditionForm 
        formMethods={formMethods} 
        onSubmit={handleUpdate} 
        isPresetConditions 
        lastMonthMenu={menuVariationList} 
      />
    </>
  );
});
