import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/system/Container";
import { FC } from "react";
import { Link } from "react-router-dom";

type Props = {
  statusCode: number;
  errorMessage?: string;
};

export const ErrorPage: FC<Props> = ({ statusCode, errorMessage }) => {
  return (
    <Container sx={{ display: "flex", justifyContent: "center" }}>
      <Grid>
        <Grid container direction="column" display={'flex'} justifyContent="center" alignItems={"center"}>
            <Typography variant="h2">{statusCode}</Typography>
            {errorMessage ?? "ページが存在しません"}
          <Grid marginTop={3}>
            <Link to="/">トップページへ</Link>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
