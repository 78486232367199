import axios from "axios";
import firebase from 'firebase/compat/app';
import { useState } from "react";

export const useDownloadArtifact = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleClickDownload = async (latestJobId: string | null, name: string, dateStr: string | null) => {
    if (latestJobId === null || dateStr === null) return;
    const downloadArtifactURL = `/jobs/${latestJobId}/download`

    firebase.auth().onAuthStateChanged(async (user) => {
      if (user === null) return;
      setIsLoading(true)
      setIsSuccess(false)

      try {
        const res = await axios.get(downloadArtifactURL, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${await user.getIdToken(false)}`,
          },
        })
        const downloadUrl = res.data.public_url;

        const fileResponse = await axios.get(downloadUrl, {
          responseType: 'blob'
        });
        const url = window.URL.createObjectURL(new Blob([fileResponse.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'menu.zip'); // ダウンロードされるファイル名を指定
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

      } catch (e: any) {
        console.error('ダウンロード中にエラーが発生しました:', e);
      } finally {
        setIsLoading(false)
        setIsSuccess(true)
      }
    })
  };

  return {
    isLoading,
    isSuccess,
    handleClickDownload,
  }
}
