import * as yup from "yup";
import { InValidInputNotAllowSymbols } from "./_template";
import { SearchDish } from "../../../types/dish";


// eslint-disable-next-line no-useless-escape
const notAllowSymbolsSchema = yup.string().matches(/^[^\-+=^$*.\[\]{}()?\"!@#%&\/\\\\,><\':;|_~`\-+=]*$/, InValidInputNotAllowSymbols);

export const DishSchema = yup.lazy((_: SearchDish) => yup.object({
  source_id: notAllowSymbolsSchema,
  name: notAllowSymbolsSchema,
}));
