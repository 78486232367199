import FormControl from "@mui/material/FormControl";
import { InputBaseComponentProps } from "@mui/material";
import TextField from "@mui/material/TextField";

import { Control, Controller, Path, UseFormTrigger } from "react-hook-form";

type Obj = {
  [x: string]: any
};

type ControlTextFieldProps<T extends Obj>  = {
  type: 'text' | 'number'
  name: string,
  control: Control<T, any>,
  trigger:  UseFormTrigger<T>,
  label?: string 
  width?: number
  inputProps?: InputBaseComponentProps
}

/**
 * react-hook-formに依存しているcomponent
 * useFormContext から受け取る control, triggerを使用する
 * @param props 
 * @returns 
 */
export const ControlTextField = <T extends Obj>(props: ControlTextFieldProps<T>) => {
  const {type, name, control, trigger, label=null, inputProps={}, width={}} = props

  return (
    <FormControl fullWidth>
      <Controller
        control={control}
        name={name as Path<T>}
        render={({ field, fieldState }) => (
          <>
            <TextField
              {...field}
              type={type}
              label={label}
              helperText={fieldState.error?.message}
              error={!!fieldState.error?.message}
              onChange={(e) => {
                // 入っている値がstringになるケースがあるため
                if (type === 'number') {
                  field.onChange(parseFloat(e.target.value));
                } else {
                  field.onChange(e.target.value);
                }
                void trigger();
              }}
              inputProps={inputProps}
              sx={{ minWidth: width }}
            />
          </>
        )}
      />
    </FormControl>
  );
};