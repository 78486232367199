import Pagination from "@mui/material/Pagination";
import Grid from "@mui/material/Grid";
import {useState} from "react";
import {MenuForMonth} from "../../../../dataModel/menu/MenuForMonth";
import {ColumnTitle} from "../../../atoms/columnTitle";
import {grey} from "@mui/material/colors";
import {LineOfMenu} from "./lineOfMenu";

type MenuViewProps = {
  menuId: string
  menuForMonth: MenuForMonth | undefined
}

export const MenuView = (props: MenuViewProps) => {
  const { menuId, menuForMonth } = props;
  const [page, setPage] = useState(1);

  return (
    <Grid
      sx={{
        display: "flex",
        flexDirection: "column",
        columnGap: (theme) => theme.spacing(4),
      }}
    >
        <Grid container>
          { menuForMonth &&
          <ColumnTitle
            title={`${menuForMonth.targetMonth.getFullYear()}年 ${menuForMonth.targetMonth.getMonth()+1}月`}
          />
          }
          { menuForMonth &&
          <Pagination
            count={menuForMonth.weeks.length}
            color="primary"
            onChange={(e, page) => setPage(page)}
            page={page}
          />
          }
        </Grid>
        <Grid
          container
          item
          sx={{
            border: `solid 0.3px ${grey[900]}`,
            borderRadius: "16px",
            padding: (theme) => theme.spacing(2),
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            marginY: (theme) => theme.spacing(4),
          }}
        >
          <Grid
            sx={{
              display: "flex",
              flexDirection: "row",
              overflowX: "scroll",
              columnGap: (theme) => theme.spacing(3),
            }}
          >
            { menuForMonth && 
              <LineOfMenu page={page} menuData={menuForMonth} menuId={menuId} /> 
            }
          </Grid>
        </Grid>
    </Grid>
  );
};

