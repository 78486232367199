export const MemberShipTypeTableColumn = ["会員種別コード", "会員種別名", "アクション"];
export const DishesTableColumn = [
  "商品コード",
  "商品名",
  "構成分類",
  "カテゴリ",
  "アクション",
];

export const PresetConditionsColumn = [
  "プリセット条件コード",
  "プリセット条件名",
  "プリセット条件アクション",
];

export const MenuVariationColumn = ["献立ID", "献立名", "アクション"];
