import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { FC, memo } from "react";
import { PresetConditionsListType } from "../../../../pages/presetConditions";
import { PresetConditionsColumn } from "../../../../const/tableColumns";
import { TableHeader } from "../../../atoms/tableHeader";
import { TableWrapper } from "../../../atoms/tableWrapper";
import { PresetConditionsActionCell } from "./presetConditionsActionCell";

type Props = {
  datum: PresetConditionsListType[]
}

export const PresetConditionsTable: FC<Props> = memo(({datum}) => {
  return (
    <TableWrapper>
      <>
        <TableHeader headCells={PresetConditionsColumn} />

        <TableBody>
          {datum.map((presetCondition, idx: number) => (
            <TableRow key={idx}>
              <TableCell
                variant="head"
                padding={"normal"}
                sx={{
                  backgroundColor: (theme) => theme.palette.secondary.main,
                  color: (theme) => theme.palette.secondary.contrastText,
                  textAlign: "center",
                }}
              >
                {presetCondition.code}
              </TableCell>
              <TableCell
                variant="head"
                padding={"normal"}
                sx={{
                  backgroundColor: (theme) => theme.palette.secondary.main,
                  color: (theme) => theme.palette.secondary.contrastText,
                  textAlign: "center",
                }}
              >
                {presetCondition.name}
              </TableCell>

              {/* アクションボタン */}
              <PresetConditionsActionCell presetConditionsId={presetCondition.id} />
            </TableRow>
          ))}
        </TableBody>

        <TableFooter>
          <TableRow />
        </TableFooter>
      </>
    </TableWrapper>
  );
});
