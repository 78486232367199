import Typography from "@mui/material/Typography";
import { FC } from "react";

type Props = {
  title: string;
};

export const ColumnTitle: FC<Props> = ({ title }) => {
  return (
    <Typography
      sx={{
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: "700",
        letterSpacing: "0.5px",
        lineHeight: "35px",
      }}
    >
      {title}
    </Typography>
  );
};
