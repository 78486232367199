import { useCallback, useEffect, useState } from "react";
import { usePost } from "../../hooks/usePost";
import api from "../../const/api";
import axios from "axios";
import { AlertColor } from "@mui/material";
import { APIPathGetMenuById, APIPathPostKickMakingMenuJob } from "../../endpoint";
import { MenuForMonth } from "../../dataModel/menu/MenuForMonth";
import { GetMenuByIdResponse, InsufficientInfo, MenuJobStatusStr, MenuMeal } from "../../types/menu";
import { assertIsDefinedOrNull } from "../../util/assertIsDefined";
import { useNavigate } from "react-router-dom";
import { ERROR_404, ERROR_500 } from "../../const/error";
import { MenuForDay, WeekOfDay } from "../../dataModel/menu/MenuForDay";
import { Menu } from "../../dataModel/menu/Menu";
import { MenuInfo } from "../../dataModel/menu/MenuInfo";
import {useAuthContext} from "../../components/organisms/AuthProvider";

export const useMenu = (
  menuId: string |  undefined,
  showSnackbar: (message: string, severity: AlertColor) => void,
) => {
  const [menu, setMenu] = useState<GetMenuByIdResponse>();
  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(false);

  const [menuForMonth, setMenuForMonth] = useState<MenuForMonth>();
  const [insufficient, setInsufficient] = useState<InsufficientInfo>({color: [], incredient: [], nutrition: [], all: []});
  const [isJobNotFound, setIsJobNotFound] = useState<boolean>(false); 
  const [isGetLoading, setGetLoading] = useState<boolean>(false); // TODO::setStateをシンプルにしないとuseFetchが使えないので抽象化できない

  // TODO: useFetch使う
  const token = useAuthContext().token ?? null;

  const router = useNavigate();

  useEffect(() => {
    if (token === null) return;

    const get_data = async () => {
      try {
        setGetLoading(true);
        const result = await api.get(APIPathGetMenuById(menuId), token);
        const menuByIdResponse: GetMenuByIdResponse = result.data;
        assertIsDefinedOrNull(menuByIdResponse);

        if (result.status === 204 ) {
          return setIsJobNotFound(true);
        }
      
        setMenu(menuByIdResponse);
        setDisabledSubmit(menuByIdResponse.status === MenuJobStatusStr.Processing);
        setInsufficient(menuByIdResponse.insufficient_info);
        
        if (menuByIdResponse.status === "Success") {
          updateMenuData(menuByIdResponse.target_month, menuByIdResponse.meals, setMenuForMonth);
        }
      } catch (error) {
        console.error(error)
        if (axios.isAxiosError(error) && error.response?.status === 404) {
          showSnackbar(ERROR_404, 'error')
          router('/404');
          return;
        }
        showSnackbar(ERROR_500, 'error')
      } finally {
        setGetLoading(false);
      }
    }
    get_data();
  }, [disabledSubmit, token]); // eslint-disable-line

  // 以下POST処理だが、実行後の挙動でGETと依存関係があるので同じhooksに集約させている
  const [ doPost, isLoading ] = usePost({
    method: 'post',
    url: APIPathPostKickMakingMenuJob(menuId),
  })

  const kickJob = useCallback(() => {
    doPost({
      onSuccess: () => {
        showSnackbar("献立生成ジョブを実行しました", 'success');
        setTimeout(() => window.location.reload(), 800);
      },
      onError: (err) => {
        showSnackbar(`ジョブ起動に失敗しました: ${err.message}`, "error");
        setTimeout(() => window.location.reload(), 800);
      }
    })
  }, [menuId, isLoading]); // eslint-disable-line

  const handleClickGenerateMenu = (e: React.MouseEvent<HTMLInputElement>) => {
    e.preventDefault();

    // TODO:: 今の作りだと成否に関わらずdisabled化&GETされてしまうので要件に合わせて調整
    setDisabledSubmit(true);
    kickJob();
  };

  const fetchLoading = (isLoading || isGetLoading);

  return {
    menu,
    insufficient,
    fetchLoading,
    disabledSubmit,
    menuForMonth,
    isJobNotFound,
    handleClickGenerateMenu,
  }
}


/**
 * calenderの日付調整など
 * @param targetMonth   //ex) "2023-01-27T00:00:00"
 * @param data 
 * @param setMenuForMonth 
 */
export function updateMenuData(targetMonth: string, meals: MenuMeal[], setMenuForMonth: (value: React.SetStateAction<MenuForMonth | undefined>) => void) {
  const targetDate = new Date(Number(targetMonth.substring(0,4)),Number(targetMonth.substring(5,7))-1,1);
  let tmpList: any[] =[]

  const sortedMeals: MenuMeal[] = meals.sort((a, b) => a.day - b.day); 

  sortedMeals.map((item: MenuMeal) => {
    const targetDay = new Date(targetDate.getFullYear(), targetDate.getMonth(), targetDate.getDate() + item['day'] -1 )
    const breakfast = item['meal']['breakfast'];
    const lunch = item['meal']['lunch'];
    const dinner = item['meal']['dinner'];

    const menuForDay: MenuForDay = {
      targetDay: targetDay,
      weekOfDay: targetDay.getDay() as unknown as WeekOfDay,
      breakfast: new Menu("breakfast",breakfast["image_url"], breakfast["is_manual"], breakfast["manual_meal_id"], new MenuInfo(breakfast['agg']["energy"], breakfast['agg']["price"], breakfast['agg']["protein"], breakfast['agg']["carb"], breakfast['agg']["fat"], breakfast['agg']["salt"], breakfast['agg']["potassium"], breakfast['agg']["phosphorus"]), breakfast['dishes']),
      lunch: new Menu("lunch",lunch["image_url"], lunch["is_manual"], lunch["manual_meal_id"], new MenuInfo(lunch['agg']["energy"], lunch['agg']["price"], lunch['agg']["protein"], lunch['agg']["carb"], lunch['agg']["fat"], lunch['agg']["salt"], lunch['agg']["potassium"], lunch['agg']["phosphorus"]), lunch['dishes']),
      dinner: new Menu("dinner",dinner["image_url"], dinner["is_manual"], dinner["manual_meal_id"], new MenuInfo(dinner['agg']["energy"], dinner['agg']["price"], dinner['agg']["protein"], dinner['agg']["carb"], dinner['agg']["fat"], dinner['agg']["salt"], dinner['agg']["potassium"], dinner['agg']["phosphorus"]), dinner['dishes']),
    };
    tmpList.push(menuForDay)
  });
  
  const day_of_the_first_date = tmpList[0].weekOfDay
  const last_date_of_this_month = new Date(targetDate.getFullYear(), targetDate.getMonth() + 1, 0);
  for (let i = 0; i < day_of_the_first_date; i++) {
    tmpList.unshift("");
  }
  for (let i = tmpList.length; i <= last_date_of_this_month.getDate(); i++) {
    tmpList.push("");
  }
  for (let i = last_date_of_this_month.getDay(); i < 7; i++) {
    tmpList.push("");
  }
  let weeks:any[] = []

  let number_of_weeks = (tmpList.length - 1) / 7
  for (let i = 0; i < number_of_weeks; i++) {
    weeks.push(tmpList.slice(i*7,(i+1)*7));
  }

  const result: MenuForMonth = {
    targetMonth: targetDate,
    weeks: weeks
  }

  setMenuForMonth(result);
}
