import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {FC, memo} from "react";
import {useParams} from "react-router-dom";
import {Input} from "../../components/molecules/Input";
import {DishDetailSection} from "../../components/organisms/DishDetail/dishDetailSection";
import {DishDetail} from "../../types/dish";
import {APIPathGetDishById} from "../../endpoint";
import {LoadingTemplate} from "../../components/template/LoadingTemplate/LoadingTemplate";
import {useSnackbar} from "../../components/organisms/SnackProvider";
import {PageHeader} from "../../components/molecules/PageHeader/PageHeader";
import {useFetch} from "../../hooks/useFetch";
import {assertIsDefined} from "../../util/assertIsDefined";


export const DishesDetailPage: FC = memo(() => {
  const { dishId } = useParams();
  const { showSnackbar } =  useSnackbar();

  const { data, isLoading, hasError, error } = useFetch<DishDetail>({
    url: APIPathGetDishById(dishId),
  })

  if (hasError) {
    showSnackbar(`データ取得に失敗しました: ${error.message}`, "error")
  }
  if (isLoading || data === null) {
    return <LoadingTemplate />
  }
  assertIsDefined(data);

  const noImagePath = "/images/NoImage1-m.png";

  return (
    <>
      <PageHeader title={`商品詳細 : ${data.dish_name}`} />
      <Grid
        container
        sx={{
          paddingTop: (theme) => theme.spacing(5),
          paddingX: (theme) => theme.spacing(3),
        }}
      >
        <DishDetailSection sectionTitle="基本情報">
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
            <Input type='text' variant="filled" formTitle={"商品コード"} value={data.source_id} disabled />
            <Input type='text' variant="filled" formTitle={"構成分類"} value={data.dish_type} disabled />
            <Input type='text' variant="filled" formTitle={"カテゴリ"} value={data.dish_category} disabled />
            <Input type='text' variant="filled" formTitle={"商品総量 [g]"} value={data.amount.toString()} disabled />
            <Input
              type="text"
              variant="filled"
              formTitle={"一人前量 [g]"}
              value={data.amount_per_person.toString()}
              disabled
            />
          </div>
        </DishDetailSection>

        <DishDetailSection sectionTitle="単価">
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
            <Input
              type='text'
              variant="filled"
              formTitle={"単価A (施設献立用) [円]"}
              value={data.price_a.toString()}
              disabled
            />
            <Input
              type='text'
              variant="filled"
              formTitle={"単価B (配食用) [円]"}
              value={data.price_b.toString()}
              disabled
            />
            <Input
              type='text'
              variant="filled"
              formTitle={"単価C (彩膳用) [円]"}
              value={data.price_c.toString()}
              disabled
            />
            <Input
              type='text'
              variant="filled"
              formTitle={"単価D (単品用) [円]"}
              value={data.price_d.toString()}
              disabled
            />
            <Input type='text' variant="filled" formTitle={"単価E [円]"} value={data.price_e.toString()} disabled />
          </div>
        </DishDetailSection>

        <DishDetailSection sectionTitle="1食あたり単価">
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
            <Input
              type='text'
              variant="filled"
              formTitle={"1食単価A (施設献立用) [円]"}
              value={Math.floor(data.price_per_person_a).toString()}
              disabled
            />
            <Input
              type='text'
              variant="filled"
              formTitle={"1食単価B (配食用) [円]"}
              value={Math.floor(data.price_per_person_b).toString()}
              disabled
            />
            <Input
              type='text'
              variant="filled"
              formTitle={"1食単価C (彩膳用) [円]"}
              value={Math.floor(data.price_per_person_c).toString()}
              disabled
            />
            <Input
              type='text'
              variant="filled"
              formTitle={"1食単価D (単品用) [円]"}
              value={Math.floor(data.price_per_person_d).toString()}
              disabled
            />
            <Input type='text' variant="filled" formTitle={"1食単価E [円]"} value={Math.floor(data.price_per_person_e).toString()} disabled />
          </div>
        </DishDetailSection>

        <DishDetailSection sectionTitle="栄養素">
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
            <Input
              type="text"
              variant="filled"
              formTitle={"エネルギー[kcal]"}
              value={data.energy.toString()}
              disabled
            />
            <Input
              type="text"
              variant="filled"
              formTitle={"たんぱく質 [g]"}
              value={data.protein.toString()}
              disabled
            />
            <Input
              type="text"
              variant="filled"
              formTitle={"脂質 [g]"}
              value={data.fat.toString()}
              disabled
            />
            <Input
              type="text"
              variant="filled"
              formTitle={"炭水化物 [g]"}
              value={data.carb.toString()}
              disabled
            />
            <Input
              type="text"
              variant="filled"
              formTitle={"食塩相当量 [g]"}
              value={data.salt.toString()}
              disabled
            />
            <Input
              type="text"
              variant="filled"
              formTitle={"カリウム相当量 [mg]"}
              value={data.potassium.toString()}
              disabled
            />
            <Input
              type="text"
              variant="filled"
              formTitle={"リン相当量 [mg]"}
              value={data.phosphorus.toString()}
              disabled
            />
          </div>
        </DishDetailSection>

        <DishDetailSection sectionTitle="1食あたり栄養素">
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
            <Input
              type="text"
              variant="filled"
              formTitle={"1食エネルギー[kcal]"}
              value={roundByFirstDecimal(data.energy_per_person).toString()}
              disabled
            />
            <Input
              type="text"
              variant="filled"
              formTitle={"1食たんぱく質 [g]"}
              value={roundByFirstDecimal(data.protein_per_person).toString()}
              disabled
            />
            <Input
              type="text"
              variant="filled"
              formTitle={"1食脂質 [g]"}
              value={roundByFirstDecimal(data.fat_per_person).toString()}
              disabled
            />
            <Input
              type="text"
              variant="filled"
              formTitle={"1食炭水化物 [g]"}
              value={roundByFirstDecimal(data.carb_per_person).toString()}
              disabled
            />
            <Input
              type="text"
              variant="filled"
              formTitle={"1食食塩相当量 [g]"}
              value={roundByFirstDecimal(data.salt_per_person).toString()}
              disabled
            />
            <Input
              type="text"
              variant="filled"
              formTitle={"1食カリウム相当量 [mg]"}
              value={roundByFirstDecimal(data.potassium_per_person).toString()}
              disabled
            />
            <Input
              type="text"
              variant="filled"
              formTitle={"1食リン相当量 [mg]"}
              value={roundByFirstDecimal(data.phosphorus_per_person).toString()}
              disabled
            />
          </div>
        </DishDetailSection>

        {/* 長い系 */}
        <Grid
          container
          item={true}
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: (theme) => theme.spacing(3),
            marginY: (theme) => theme.spacing(3),
          }}
        >
          <Input type="text" variant="filled" formTitle={"アレルゲン"} value={data.allergy.toString()} disabled />

          <Input type="text" variant="filled" formTitle={"原材料"} value={data.ingredients.toString()} disabled />
        </Grid>

        <DishDetailSection sectionTitle="画像">
          <Box
            component={"img"}
            src={data.dishImage}
            onError={e => {
              e.currentTarget.src = noImagePath;
            }}
            alt="Logo"
            sx={{
              padding: (theme) => theme.spacing(3),
              border: "solid 1px #000",
              borderRadius: "4px",
              maxWidth: "800px",
            }}
          />
        </DishDetailSection>
      </Grid>
    </>
  );
});

/**
 * 小数点一桁に丸める
 */
function roundByFirstDecimal(val: number): number {
  return Math.round(val * 10) / 10;
}
