import { FC, memo } from "react";
import { LoadingTemplate } from "../../components/template/LoadingTemplate/LoadingTemplate";
import { MenuCreateForm } from "../../components/organisms/MenuCreate/MenuCreateForm";
import { useCreateMenu } from "./useCreateMenu.hooks";
import Grid from "@mui/material/Grid";
import { FormProvider } from "react-hook-form";
import { PageHeader } from "../../components/molecules/PageHeader/PageHeader";

export const MenuCreatePage: FC = memo(() => {
  const {isLoading, formMethods, handleSubmit} = useCreateMenu();
  if (isLoading) {
    return <LoadingTemplate />
  }

  return (
    <>
      <PageHeader title={"新規献立作成"} />
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={7}>
          <FormProvider  {...formMethods}>
            <MenuCreateForm formMethods={formMethods} handleSubmit={handleSubmit} />
          </FormProvider>
        </Grid>
      </Grid>
    </>
  );
});
