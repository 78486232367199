import Grid from "@mui/material/Grid";
import { FC } from "react";
import { ColumnTitle } from "../../../../atoms/columnTitle";
import { OptionSpecificConditionsList } from "../../../../../const/specifiConditionType";
import { Typography } from "@mui/material";
import { ConditionDisableField } from "./conditionDisableField";

export const SpecificCondition: FC = () => {
  return (
    <>
      <ColumnTitle title="切り替え可能な制約条件一覧" />
      <Grid
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          justifyContent: "space-between",
          marginTop: (theme) => theme.spacing(6),
          paddingX: (theme) => theme.spacing(12),
        }}
      >
        <ColumnTitle title={"条件名"} />
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            columnGap: (theme) => theme.spacing(12),
          }}
        >
          <ColumnTitle title={"無効化する条件を選択"} />
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          marginY: (theme) => theme.spacing(3),
          paddingX: (theme) => theme.spacing(6),
        }}
      >
        {OptionSpecificConditionsList.map((item) => (
          <ConditionItem conditionTitle={item.ConditionTitle} formName={item.formName} key={item.formName} />
        ))}       
      </Grid>
      <Grid container item
        sx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
      />
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          marginY: (theme) => theme.spacing(3),
        }}
      >
      </Grid>
    </>
  );
};

type Props = {
  conditionTitle: string;
  formName: string;
};
const ConditionItem: FC<Props> = (({conditionTitle, formName}) => {
  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginY: (theme) => theme.spacing(3),
        marginX: (theme) => theme.spacing(4),
      }}
    >
      <Typography sx={{ fontWeight: "bold"}}>{conditionTitle}</Typography>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          columnGap: (theme) => theme.spacing(20),
        }}
      >
        <ConditionDisableField formName={formName} />
      </Grid>
    </Grid>
  );
}); 
