import React, { useState, ChangeEvent } from 'react';
import { ControlSelect } from "./ControlSelect/ControlSelect";
import { useFormContext } from "react-hook-form";

interface DayPickerProps {
    name: string;
    placeholder: string;
    defaultValue: string;
}

const DayPicker: React.FC<DayPickerProps> = ({ name, placeholder, defaultValue }) => {
  // 曜日のリストを定義
  const days = [
    {"title" : "日",  "value" : "Sun"},
    {"title" : "月",  "value" : "Mon"},
    {"title" : "火",  "value" : "Tue"},
    {"title" : "水",  "value" : "Wed"},
    {"title" : "木",  "value" : "Thu"},
    {"title" : "金",  "value" : "Fri"},
    {"title" : "土",  "value" : "Sat"},
  ];

  // 選択された曜日をstateで管理
  // const [selectedDay, setSelectedDay] = useState('');
  const {control, trigger} = useFormContext();

  // // ドロップダウンで選択された曜日を更新
  // const handleDayChange = (e: ChangeEvent<HTMLSelectElement>) => {
  //   setSelectedDay(e.target.value);
  // };

  return (
    <ControlSelect
        name={name}
        options={days}
        control={control}
        label={placeholder}
        defaultValue={defaultValue}
    />
  );
};

export default DayPicker;