import { memo, FC, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useSnackbar } from "../../components/organisms/SnackProvider";

export const LogoutPage: FC = memo(() => {
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    firebase
      .auth()
      .signOut()
      .then(function () {
        showSnackbar("ログアウトしました", "success");
      })
      .catch(function (error) {
        showSnackbar(`ログアウトに失敗しました. 詳細 : ${error}`, "error");
      });
  }, []);

  return <></>;
});
