import Grid from "@mui/material/Grid";
import { FC } from "react";
import { ColumnTitle } from "../../../../atoms/columnTitle";
import { useFormContext } from "react-hook-form";
import { ConditionFormValues } from "../../../../../types/condition";
import { Box, Typography } from "@mui/material";
import { ControlTextField } from "../../../../atoms/ControlTextField/ControlTextField";

export const BasicCondition: FC = () => {
  return (
    <Grid
      container
      sx={{
        rowGap: (theme) => theme.spacing(4),
      }}
    >
      <ColumnTitle title={"価格入力 (月平均)"} />
      <Grid container
        sx={{
          border: "solid 1px #000",
          paddingX: (theme) => theme.spacing(16),
          paddingY: (theme) => theme.spacing(4),
          borderRadius: "8px",
        }}
      >
        <Grid container item sx={{ flexDirection: "row", justifyContent: "flex-start", }}>
          <TargetFormSection targetType="Price" menuType={'breakfast'} label={'朝食価格 [円]'} />
          <TargetFormSection targetType="Price" menuType={'lunch'} label={'昼食価格 [円]'} />
          <TargetFormSection targetType="Price" menuType={'dinner'} label={'夕食価格 [円]'} />
        </Grid>
      </Grid>

      <ColumnTitle title={"基本栄養素 (一日)"} />
      <Grid
        container
        sx={{
          border: "solid 1px #000",
          paddingX: (theme) => theme.spacing(16),
          paddingY: (theme) => theme.spacing(4),
          borderRadius: "8px",
        }}
      >
        <Grid container item sx={{ flexDirection: "row", justifyContent: "flex-start", }}>
          <TargetFormSection targetType="Energy" menuType={'daily'} label={'エネルギー [kcal]'} />
          <TargetFormSection targetType="Protein" menuType={'daily'} label={'たんぱく質 [g]'} />
          <TargetFormSection targetType="Salt" menuType={'daily'} label={'食塩相当量 [g]'} />
          <TargetFormSection targetType="Potassium" menuType={'daily'} label={'カリウム [mg]'} />
          <TargetFormSection targetType="Phosphorus" menuType={'daily'} label={'リン [mg]'} />
        </Grid>
      </Grid>

      <ColumnTitle title={"基本栄養素 (一食)"} />
      <Grid
        container
        sx={{
          border: "solid 1px #000",
          paddingX: (theme) => theme.spacing(16),
          paddingY: (theme) => theme.spacing(4),
          borderRadius: "8px",
        }}
      >
        <Grid container item sx={{ flexDirection: "row", justifyContent: "flex-start", }}>
          <TargetFormSection targetType="Energy" menuType={'eachBreakfast'} label={'エネルギー (朝) [kcal]'} />
          <TargetFormSection targetType="Energy" menuType={'eachLunch'} label={'エネルギー (昼) [kcal]'} />
          <TargetFormSection targetType="Energy" menuType={'eachDinner'} label={'エネルギー (夕) [kcal]'} />
          <TargetFormSection targetType="Protein" menuType={'eachBreakfast'} label={'たんぱく質 (朝) [g]'} />
          <TargetFormSection targetType="Protein" menuType={'eachLunch'} label={'たんぱく質 (昼) [g]'} />
          <TargetFormSection targetType="Protein" menuType={'eachDinner'} label={'たんぱく質 (夕) [g]'} />
          <TargetFormSection targetType="Salt" menuType={'eachBreakfast'} label={'食塩相当量 (朝) [g]'} />
          <TargetFormSection targetType="Salt" menuType={'eachLunch'} label={'食塩相当量 (昼) [g]'} />
          <TargetFormSection targetType="Salt" menuType={'eachDinner'} label={'食塩相当量 (夕) [g]'} />
          <TargetFormSection targetType="Potassium" menuType={'eachBreakfast'} label={'カリウム (朝) [mg]'} />
          <TargetFormSection targetType="Potassium" menuType={'eachLunch'} label={'カリウム (昼) [mg]'} />
          <TargetFormSection targetType="Potassium" menuType={'eachDinner'} label={'カリウム (夕) [mg]'} />
          <TargetFormSection targetType="Phosphorus" menuType={'eachBreakfast'} label={'リン (朝) [mg]'} />
          <TargetFormSection targetType="Phosphorus" menuType={'eachLunch'} label={'リン (昼) [mg]'} />
          <TargetFormSection targetType="Phosphorus" menuType={'eachDinner'} label={'リン (夕) [mg]'} />
        </Grid>
      </Grid>
    </Grid>
  );
};

type TargetType = "Price" | "Carb" | "Energy" | "Fat" | "Salt" | "Protein" | "Potassium" | "Phosphorus"
type Props = {
  targetType: TargetType
  menuType: "breakfast" | "lunch" | "dinner" | "daily" | "eachBreakfast" | "eachLunch" | "eachDinner";
  label: "朝食価格 [円]" | "昼食価格 [円]" | "夕食価格 [円]" | "エネルギー [kcal]" | "たんぱく質 [g]" | "食塩相当量 [g]" | "カリウム [mg]" | "リン [mg]" | "エネルギー (朝) [kcal]" | "エネルギー (昼) [kcal]" | "エネルギー (夕) [kcal]" | "たんぱく質 (朝) [g]" | "たんぱく質 (昼) [g]" | "たんぱく質 (夕) [g]" | "食塩相当量 (朝) [g]" | "食塩相当量 (昼) [g]" | "食塩相当量 (夕) [g]" | "カリウム (朝) [mg]" | "カリウム (昼) [mg]" | "カリウム (夕) [mg]" | "リン (朝) [mg]" | "リン (昼) [mg]" | "リン (夕) [mg]"
};

const TargetFormSection: FC<Props> = (props: Props) => {
  const { menuType, targetType, label } = props;

  const { control, trigger } = useFormContext<ConditionFormValues>();
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Typography
        sx={{
          marginY: (theme) => theme.spacing(2),
          borderBottom: "solid 1px #000",
          width: "fit-content",
        }}
      >
        {label}
      </Typography>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          columnGap: (theme) => theme.spacing(3),
        }}
      >
        <Typography sx={{ fontSize: "18px", color: "gray", }}>min</Typography>
        <Box>
          <ControlTextField
            type="number"
            name={`${menuType}Min${targetType}` as const}
            control={control}
            trigger={trigger}
            inputProps={{
              step: 1,
              min: 1,
              max: 9999,
              type: "number",
            }}
          />
        </Box>
        <Typography sx={{ textAlign: "center", alignContent: "center", }}>
          ~
        </Typography>
        <Typography sx={{ fontSize: "18px", color: "gray", }}>max</Typography>
        <Box>
          <ControlTextField
            type="number"
            name={`${menuType}Max${targetType}` as const}
            control={control}
            trigger={trigger}
            inputProps={{
              step: 1,
              min: 1,
              max: 9999,
              type: "number",
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
