import Grid from "@mui/material/Grid";
import { FC, memo } from "react";
import { useParams } from "react-router-dom";
import { MUIButton } from "../../components/atoms/Button/Button";
import { MenuListTable } from "../../components/organisms/Menu/menuListTable";
import { useSnackbar } from "../../components/organisms/SnackProvider";
import { useNavigate } from "react-router-dom";
import { LoadingTemplate } from "../../components/template/LoadingTemplate/LoadingTemplate";
import { useMenuList } from "./useMenuList.hooks";
import { PageHeader } from "../../components/molecules/PageHeader/PageHeader";
import { IconButton, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export const MenusPage: FC = memo(() => {
  const { membershipTypeId } = useParams();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { isLoading, error, menuVariationList, targetMonth, handleClickPrevious, handleClickNext} = useMenuList(membershipTypeId);

  if (error) {
    showSnackbar(`取得時にエラーが発生しました。: ${error}`, 'error')
  }
  // 初回のみページ全体のloadingを入れている。(ボタンなど見せないため)
  if (isLoading && targetMonth === new Date()) {
    return <LoadingTemplate />
  }

  return (
    <>
      <Grid sx={{ display: "flex",}}>
        <PageHeader title={"献立一覧"} />
        <div style={{ flexGrow: 1 }} />
        <Grid
          sx={{
            display: "flex",
            columnGap: (theme) => theme.spacing(4),
          }}
        >
          {/* <UpdateButton
            text={"過去の献立をコピーする"}
            onPush={() => {
              showSnackbar("その機能は実装中です", "error");
            }}
          /> */}
          {/* ここのrouting直す */}
          <MUIButton
            text={"新規献立追加"}
            onPush={() => navigate(`/membership_type/${membershipTypeId}/menu/${targetMonth.toISOString().split('T')[0]}/create`)}
          />
        </Grid>
      </Grid>

      <Grid sx={{ display: "flex", justifyItems: "center", alignItems: "center"}}>
        <IconButton onClick={handleClickPrevious}>
          <ArrowBackIosIcon />
        </IconButton>
        <Typography>{`${targetMonth.getFullYear()}年${targetMonth.getMonth() + 1}月`}</Typography>
        <IconButton onClick={handleClickNext}>
          <ArrowForwardIosIcon />
        </IconButton>
      </Grid>

      { 
        <Grid
          container
          sx={{
            paddingTop: (theme) => theme.spacing(5),
            paddingX: (theme) => theme.spacing(3),
          }}
        >
          <MenuListTable dataList={menuVariationList} membershipTypeId={membershipTypeId ?? ''} />
        </Grid>
      }
    </>
  );
});
