import Grid from "@mui/material/Grid";
import { FC } from "react";
import { ColumnTitle } from "../../../atoms/columnTitle";
import { ControlTextField } from "../../../atoms/ControlTextField/ControlTextField";
import { ConditionFormValues } from "../../../../types/condition";
import { useFormContext } from "react-hook-form";
import { ControlSelect } from "../../../atoms/ControlSelect/ControlSelect";

import { PriceType } from "../../../../dataModel/priceType/PriceType";

type Props = {
  isPresetConditions: boolean,
}

export const FormHeaderSection: FC<Props> = ({isPresetConditions}) => {
  const { control, trigger } = useFormContext<ConditionFormValues>();

  return (
    <Grid container sx={{ marginY: (theme) => theme.spacing(4), rowGap: (theme) => theme.spacing(3),}}>
      {/* プリセットの時しか、nameとcodeを設定できない */}
      {isPresetConditions === true ? (
        <>
          <Grid item container direction="row" spacing={4}>
            <Grid container item sx={{ justifyContent: "space-between", }}>
              <Grid item>
                <ColumnTitle title={"プリセット条件名"} />
              </Grid>
              <Grid item>
                <ControlTextField
                  type="text"
                  control={control}
                  trigger={trigger}
                  name={`presetConditionName`}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item container direction="row" spacing={4}>
          <Grid container item sx={{ justifyContent: "space-between"}}>
            <Grid item>
              <ColumnTitle title={"プリセット条件コード"} />
            </Grid>
            <Grid item>
              <ControlTextField
                type="text"
                control={control}
                trigger={trigger}
                name={`presetConditionCode`}
              />
            </Grid>
          </Grid>
        </Grid>
      </>
      ): <></>}


      <Grid item container direction="row" spacing={4}>
        <Grid container item sx={{ justifyContent: "space-between",}}>
          <Grid item>
            <ColumnTitle title={"価格種別"} />
          </Grid>
          <Grid item>
            <ControlSelect name="priceType" options={PriceType} control={control}/>
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  );
};
