export type GetMenuByIdResponse = {
  id: string,
  name: string,
  membership_type_id: string,
  preset_conditions_id: string,

  latest_create_job_of_monthly_meals_id: string | null,
  average_prices: {
    breakfast: number,
    lunch: number,
    dinner: number,
  },
  insufficient_info: InsufficientInfo,
  meals: meals[],
  manual_meals: any[] | [],
  is_manual_job_run: boolean,
  target_month: string,
  status: MenuJobStatus,
  created_at: string,
  updated_at: string,
}

export type MenuMeal = {
  day: number,
  date: string, // yyyy-mm-dd
  agg: Agg
  meal: meal
}

type Agg = {
  energy: number
  price: number
  protein: number
  salt: number
  potassium: number
  phosphorus: number
}

type meals = {
  day: number,
  date: string, // yyyy-mm-dd
  agg: Agg,
  meal: meal,
}

type meal = {
  breakfast: any
  dinner: any
  lunch: any
}

export type MealType =  "breakfast" | "lunch" | "dinner";
export const mealTypeTitle = {
  "breakfast": "朝食",
  "lunch": "昼食",
  "dinner": "夕食",
} as const


// 献立評価
export type InsufficientInfo = {
  color: ColorInfo[] | [],
  incredient: IncredientInfo[] | [],
  nutrition: NutritionInfo[] | [],
  all: [],
}

export type ColorInfo = {
  meal_type: "breakfast" | "lunch" | "dinner"
  target_day: string,
}

export type IncredientInfo = {
  ingredient: string,
  meal_type: "breakfast" | "lunch" | "dinner",
  target_day: string,
  source_uid: {value: string},
}

export type NutritionInfo = {
  meal_type: "breakfast" | "lunch" | "dinner"
  target_day: string,
  nutrition: "salt" | "energy" | "protein" | "potassium" | "phosphorus",
}

export type MenuJobStatus =  "Success" | "Error" | "NoResult" | "Processing";
export const MenuJobStatusStr = {
  "Success": "Success",
  "Error": "Error",
  "NoResult": "NoResult",
  "Processing": "Processing",
} as const

// APIからきた値をUI用に加工したもの
export type SortedColorInfo = {
  meal_type: "朝食" | "昼食" | "夕食",
  target_day: string, // yyyy-mm-dd
}

export type SortedIncredient = {
  ingredient: string
  val: SortedIncredientInfo[]
}
export type SortedIncredientInfo = {
  meal_type: "朝食" | "昼食" | "夕食",
  target_day: string,
  source_uid: {
    value: string
  },
}

export type SortedNutritionList = {
  nutrition: "食塩" | "エネルギー" | "たんぱく質" | "カリウム" | "リン",
  val: SortedNutritionInfo[]
}
export type SortedNutritionInfo = {
  meal_type: string,
  target_day: string, // yyyy-mm-dd
}