import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { FC } from "react";
import { menuDetail, MenuPart } from "../../../../types/menuDetail";
import { ColumnTitle } from "../../../atoms/columnTitle";
import { Input } from "../../../molecules/Input";
import { grey, orange } from '@mui/material/colors';

/**
 * 並び順を以下の法則で行う
 * 昼: 朝食向け主菜 > 添え物 > 副菜( 朝食向け副菜 > 朝食向けその他> 温菜 > 冷菜 > 箸休め) > その他 (主菜、温菜 > 冷菜 > 箸休めは基本存在しない)
 * 昼: 主菜 > 添え物 > 副菜(温菜 > 冷菜 > 箸休め) > その他
 * @param menuParts 
 * @returns 
 */
function sortDish( menuParts : MenuPart[] | undefined, type: '朝食' | '昼食' | '夕食'): MenuPart[] {
  if (menuParts === undefined) return [];

  let mainDishes: MenuPart[] = [];
  let soemonoDishes: MenuPart[] = [];
  let sideDishes: MenuPart[] = [];
  let breakfastMainDishes: MenuPart[] = [];
  let otherDishes: MenuPart[] = [];

  for (const menuPart of menuParts) {
    if (menuPart.cat_M === "主菜") {
      mainDishes.push(menuPart);
      continue;
    }
    if (menuPart.cat_M === "添え物") {
      soemonoDishes.push(menuPart);
      continue;
    }
    if (menuPart.cat_M === "副菜") {
      sideDishes.push(menuPart)
      continue
    }
    if (menuPart.cat_M === "朝食向け主菜") {
      breakfastMainDishes.push(menuPart)
      continue
    }
    otherDishes.push(menuPart)
  }

  if (sideDishes.length > 1) {
    const sortOrder: {[key: string]: number} = {
      '温菜': 3,
      '冷菜': 4,
      '箸休め': 5,
    }
    if (type === '朝食') {
      sortOrder['朝食向け副菜'] = 1
      sortOrder['朝食向けその他'] = 2
    }

    sideDishes.sort((a, b) => {
      const orderA = sortOrder[a.cat_S] || Infinity;
      const orderB = sortOrder[b.cat_S] || Infinity;

      return orderA - orderB;
    })
  }

  if (type === '朝食') {
    // 朝食向け主菜 & 主菜　が万が一混合した時対策でmainDishesも入れている
    return [...breakfastMainDishes, ...mainDishes, ...soemonoDishes, ...sideDishes, ...otherDishes];
  }

  return [...mainDishes, ...soemonoDishes, ...breakfastMainDishes, ...sideDishes, ...otherDishes];
}

type Props = {
  title: '朝食' | '昼食' | '夕食';
  data: menuDetail | undefined;
};
export const MenuPartsComponent: FC<Props> = ({ title, data }) => {
  const sortedMenuParts  = sortDish(data?.menuParts, title);
  const labelWidth = "170px";

  if (data === undefined) {
    return (
      <Grid
        container
        sx={{
          border: "solid 1px #000",
          padding: (theme) => theme.spacing(2),
          borderRadius: "8px",
        }}
      >
        <ColumnTitle title={title} />
        <Grid container sx={{ display: "flex", flexDirection: "row"}}>
          対象献立なし
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid
      container
      sx={{
        border: "solid 1px #000",
        padding: (theme) => theme.spacing(2),
        borderRadius: "8px",
        paddingLeft: "30px",
      }}
    >
      <ColumnTitle title={title} />
      <Grid container sx={{ display: "flex", flexDirection: "row"}} marginLeft={10}>
        <Grid sx={{ display: "flex", flexDirection: "column", rowGap: (theme) => theme.spacing(3), marginY: (theme) => theme.spacing(3),}}>
          { data.isManual ? (
            <Box
              display={"flex"} justifyContent="center" alignItems={"center"}
              sx={{
                border: `solid 0.5px grey`,
                borderRadius: "4px",
                height: 500,
                width: 660,
                display: "flex",
                flexFlow: "column",
                backgroundColor: grey[200]
              }}
            >
              <Typography variant="h4">献立生成前</Typography>
              <Typography variant="body2" marginTop={2}>画像を表示するには、前のページに戻り「献立を生成する」を実行してください</Typography>
            </Box>
          ) : (
            <Box component={"img"} src={data.menuImage} alt="Logo"
              sx={{
                border: "solid 1px #000",
                borderRadius: "4px",
                height: 500,
                width: 660,
              }}
            />
          )}
          <Grid container justifyContent="center" alignItems="center">
            <Grid  display="block">
              <Grid display={"flex"}>
                <Typography sx={{ width: labelWidth }}>価格 [円]</Typography>
                <Typography sx={{ textAlign: "center" }}>{data.price}</Typography>
              </Grid>
              <Grid display={"flex"}>
                <Typography sx={{ width: labelWidth }}>エネルギー [kcal]</Typography>
                <Typography sx={{ textAlign: "center" }}>{data.energy}</Typography>
              </Grid>
              <Grid display={"flex"}>
                <Typography sx={{ width: labelWidth }}>たんぱく質 [g]</Typography>
                <Typography sx={{ textAlign: "center" }}>{data.protein}</Typography>
              </Grid>
              <Grid display={"flex"}>
                <Typography sx={{ width: labelWidth }}>脂質 [g]</Typography>
                <Typography sx={{ textAlign: "center" }}>{data.fat}</Typography>
              </Grid>
              <Grid display={"flex"}>
                <Typography sx={{ width: labelWidth }}>炭水化物 [g]</Typography>
                <Typography sx={{ textAlign: "center" }}>{data.carb}</Typography>
              </Grid>
              <Grid display={"flex"}>
                <Typography sx={{ width: labelWidth }}>食塩相当量 [g]</Typography>
                <Typography sx={{ textAlign: "center" }}>{data.salt}</Typography>
              </Grid>
              <Grid display={"flex"}>
                <Typography sx={{ width: labelWidth }}>カリウム [mg]</Typography>
                <Typography sx={{ textAlign: "center" }}>{data.potassium}</Typography>
              </Grid>
              <Grid display={"flex"}>
                <Typography sx={{ width: labelWidth }}>リン [mg]</Typography>
                <Typography sx={{ textAlign: "center" }}>{data.phosphorus}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "40%",
            marginLeft: 20,
          }}
        >
          { data.isManual && (
            <Grid display="flex" justifyContent="center" bgcolor={orange[100]}
              sx={{
                borderRadius: 20,
                marginBottom: 2,
                width:300,
              }}
            >
              <Typography variant="h6">献立指定が有効</Typography>
            </Grid>

          )}
          { sortedMenuParts.length > 0 ?  sortedMenuParts.map(( menuParts: MenuPart, idx: number ) => (
            <Box key={idx}>
              <Input
                key={menuParts.code}
                type="text"
                variant="filled"
                formTitle={menuParts.cat_M === '副菜' ? menuParts.cat_S : menuParts.cat_M}
                value={menuParts.name}
                disabled
              />
            </Box>
          )): (
            <Typography>データが存在しません</Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};