import TableCell from "@mui/material/TableCell";
import { memo, FC } from "react";
import { LinkButton } from "../../../atoms/linkButton";

type Props = {
  url: string;
};

export const DishTableActionCell: FC<Props> = memo(({ url }) => {
  return (
    <TableCell
      variant="body"
      padding={"normal"}
      sx={{
        textAlign: "center",
      }}
    >
      <LinkButton url={url} text={"商品詳細を見る"} />
    </TableCell>
  );
});
