import { Grid, LinearProgress, Typography } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { Box } from "@mui/system";
import { useState } from "react";
import { TabPanel } from "../../components/atoms/TabPanel";
import { PageTitle } from "../../components/atoms/pageTitle";
import { FileUploadCard } from "../../components/molecules/FileUploadCard";
import { usePostData } from "./usePostData";
import { usePostDishImages } from "./usePostDishImage";


const MasterPage = () => {
  const  { ...props } = usePostData(); 
  const {...postDishImagesProps } = usePostDishImages();
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <>
      <PageTitle title={`マスタ管理`} />
      { (props.isLoading || postDishImagesProps.isLoading) && <LinearProgress color="primary" sx={{ marginTop: 3 }}/>}
      <Box sx={{ borderBottom: 1, mt: 5, borderColor: "divider" }}>
        <Tabs
          value={tabIndex}
          onChange={(_: React.SyntheticEvent, newValue: number) => {
            setTabIndex(newValue);
          }}
          variant="fullWidth"
        >
          <Tab label="商品マスタ" />
          <Tab label="画像マスタ" />
        </Tabs>
      </Box>
      <Grid container padding={2} display={"flex"} justifyContent={"center"} alignItems="center" direction="column">
        <Typography sx={{ mt: 2.5 }} color="text.secondary">
          アップロードされたデータを使って献立生成を行うようになります。
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          過去の献立データに関しては保持されるため影響は出ません。
        </Typography>
      </Grid>
      <Grid padding={2} display={"flex"} justifyContent={"center"}>
        <TabPanel value={tabIndex} index={0}>
          <FileUploadCard
            title="商品マスタ"
            onDrop={props.onDrop}
            onDragOver={props.onDragOver}
            onClick={props.handleButtonClick}
            onChange={props.onFileChange}
            inputRef={props.inputRef}
            accept=".csv"
            multiple={false}
          />
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <FileUploadCard
            title="画像マスタ"
            onDrop={postDishImagesProps.onDrop}
            onDragOver={postDishImagesProps.onDragOver}
            onClick={postDishImagesProps.handleButtonClick}
            onChange={postDishImagesProps.onFileChange}
            inputRef={postDishImagesProps.inputRef}
            description="画像は「商品コード.jpg」形式のみ対応しています。ファイル名は数字8桁で先頭は0埋めしてください。(例: 00000001.jpg)"
            accept=".jpg"
            multiple
          />
        </TabPanel>
      </Grid>
    </>
  );
}

export default MasterPage;