import Grid from "@mui/material/Grid";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableFooter from "@mui/material/TableFooter";

import {FC, memo} from "react";
import {MemberShipTypeTableColumn} from "../../../../const/tableColumns";
import {APIPathGetMembershipTypes} from "../../../../endpoint";
import {MembershipType} from "../../../../types/membershipType";
import {TableHeader} from "../../../atoms/tableHeader";
import {TableWrapper} from "../../../atoms/tableWrapper";
import {useSnackbar} from "../../SnackProvider";
import {LoadingTemplate} from "../../../template/LoadingTemplate/LoadingTemplate";
import {MembershipTypeActionCell} from "./membershipTypeActionColumn";
import {useFetch} from "../../../../hooks/useFetch";

export const MembershipTypeTable: FC = memo(() => {
  const { showSnackbar } =  useSnackbar();
  const { data, isLoading, hasError, error } = useFetch<MembershipType[]>({
    url: APIPathGetMembershipTypes,
  })
  if (hasError) {
    showSnackbar(`データ取得に失敗しました: ${error.message}`, "error");
  }
  if (isLoading) {
    return <LoadingTemplate/>
  }

  return (
    <>
      <Grid
        container
        sx={{
          paddingTop: (theme) => theme.spacing(5),
          paddingX: (theme) => theme.spacing(3),
        }}
      >
        <TableWrapper>
          <>
            <TableHeader headCells={MemberShipTypeTableColumn}/>
            <TableBody>
              {data?.map((response_data, idx: number) => (
                <TableRow key={idx}>
                  <TableCell
                    variant="head"
                    padding={"normal"}
                    sx={{
                      backgroundColor: (theme) => theme.palette.secondary.main,
                      color: (theme) => theme.palette.secondary.contrastText,
                      textAlign: "center",
                    }}
                  >
                    {response_data.code}
                  </TableCell>
                  <TableCell
                    variant="head"
                    padding={"normal"}
                    sx={{
                      backgroundColor: (theme) => theme.palette.secondary.main,
                      color: (theme) => theme.palette.secondary.contrastText,
                      textAlign: "center",
                    }}
                  >
                    {response_data.name}
                  </TableCell>
                  <MembershipTypeActionCell url={`/membership_type/${response_data.id}/menu`}/>
                </TableRow>
              ))}
            </TableBody>

            <TableFooter>
              <TableRow key='footer-row'/>
            </TableFooter>
          </>
        </TableWrapper>
        </Grid>
    </>
  );
});
