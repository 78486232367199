import { AxiosError } from "axios";
import { useCallback, useEffect, useState } from "react";
import api from "../../const/api";
import { APIPathGetMenuForLastMonthMenu, APIPathGetMenusByMembershipId } from "../../endpoint";
import { MenuListType } from "../../types/menus";
import { useContextData } from "../../context/ContextWrapper";
import {useAuthContext} from "../../components/organisms/AuthProvider";

export const useLastMonthMenuList = () => {
  const [menuVariationList, setMenuVariationList] = useState([] as MenuListType[]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError | null>(null);
  const token = useAuthContext().token;

  // TODO:: usefetchをより抽象化して使いやすくする or swrを入れる
  // handleClickが押されてMonthが変更 → fetchすることで初めてページが送られる作りなためuseEffectの副作用を使用していない
  const handleChangePage = useCallback(async () => {
    if (token === null || token === undefined) return;

    try {
      setIsLoading(true);
      const result = await api.get(APIPathGetMenuForLastMonthMenu, token);
      const data: any = {};
      data[""] = {"title" : "（設定なし）", "value" : ""};
      data["manual"] = {"title" : "手動設定（アップロードした先月末データ）", "value" : "manual"};
      for (const value of result.data) {
        data[value.id] = {"title" : value.name, "value" : value.id};
      }
      setMenuVariationList(data);
    } catch (e: unknown) {
      setError(error as AxiosError);
    } finally {
      setIsLoading(false);
    }
  },[token]);  // eslint-disable-line
  
  useEffect(() => {
    handleChangePage();
  }, [handleChangePage]);

  return {
    isLoading,
    error,
    menuVariationList,
  }
};
