import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {FC, memo} from "react";
import {useNavigate} from "react-router-dom";
import {APIPathGetPresetConditionsList, APIPathPostCreatePresetConditions} from "../../endpoint";
import {LoadingSpinner} from "../../components/atoms/LoadingSpinner/LoadingSpinner";
import {PageTitle} from "../../components/atoms/pageTitle";
import {MUIButton} from "../../components/atoms/Button/Button";
import {PresetConditionsTable} from "../../components/organisms/PresetConditions/presetConditionsTable";
import {useFetch} from "../../hooks/useFetch";
import {useSnackbar} from "../../components/organisms/SnackProvider";
import {LoadingTemplate} from "../../components/template/LoadingTemplate/LoadingTemplate";

export type PresetConditionsListType = {
  id: string;
  code: string;
  name: string;
  created_at: string;
  updated_at: string;
};

export const PresetConditionsPage: FC = memo(() => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const { data, isLoading, hasError, error } = useFetch<PresetConditionsListType[]>({
    url: APIPathGetPresetConditionsList,
  })
  if (hasError) {
    showSnackbar(`データ取得に失敗しました: ${error.message}`, "error");
  }

  if (isLoading) {
    return <LoadingTemplate/>
  }

  const handleClickAddPreset = () => {
    navigate(APIPathPostCreatePresetConditions);
  }

  return (
    <>
      <Grid sx={{ display: "flex",}}>
        <PageTitle title={"プリセット条件一覧"} />
        <div style={{ flexGrow: 1 }} />
        <MUIButton
          text={"新規プリセット条件追加"}
          onPush={handleClickAddPreset}
        />
      </Grid>

      <Grid
        container
          sx={{
            paddingTop: (theme) => theme.spacing(5),
            paddingX: (theme) => theme.spacing(3),
          }}
      >
        <PresetConditionsTable datum={data ?? []} />
      </Grid>
    </>
  );
});
