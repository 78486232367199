import { FC, memo } from "react";
import { useParams } from "react-router-dom";
import { ConditionForm } from "../../components/organisms/ConditionForm";
import { useConditionsForm } from "./useConditionsForm.hooks";
import { useLastMonthMenuList } from "./useLastMonthMenu.hooks";
import { PageHeader } from "../../components/molecules/PageHeader/PageHeader";
import {LoadingTemplate} from "../../components/template/LoadingTemplate/LoadingTemplate";


export const EditConditionsPage: FC = memo(() => {
  const { menuId } = useParams();
  const { formMethods, handleUpdate } = useConditionsForm(menuId);
  const { isLoading, error, menuVariationList, } = useLastMonthMenuList();

  return (
    <>
      {
        isLoading ? <LoadingTemplate /> :
          <>
          <PageHeader title={"制約条件編集"} />
          <ConditionForm formMethods={formMethods} onSubmit={handleUpdate} isPresetConditions={false} lastMonthMenu={menuVariationList} />
          </>
      }
    </>
  );
});
