import * as yup from "yup";
import { ConditionFormValues } from "../../../types/condition";
import { InValidInputMaxNum, InValidInputMinNum, InValidInputNotAllowSymbols, InValidInputNumber, isValidInputRange, isValidMaxNum, isValidMinNum, mustBeInput, onlyNumber } from "./_template";

/**
 * numberの場合、default値 → 未入力にすると、typeErrorが発火するのでmustBeInputを使用(不正値はHTML側で守ってくれる)
 */
export const ConditionSchema =
  yup.lazy((rootValue: ConditionFormValues) =>
    yup.object({
      presetConditionName: yup.string().matches(/^[^\-+=^$*.\[\]{}()?\"!@#%&\/\\\\,><\':;|_~`\-+=]*$/, InValidInputNotAllowSymbols).required(mustBeInput),
      presetConditionCode: yup.number().integer().typeError(onlyNumber)
        .test("check range", "プリセット制約条件のコードは三桁である必要があります。ex. 001",() => isValidInputRange(rootValue.presetConditionCode, 3),),
      priceType: yup.string().required(mustBeInput),
      breakfastMinPrice: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check min", InValidInputMinNum, () => isValidMinNum(rootValue.breakfastMinPrice, rootValue.breakfastMaxPrice)),
      breakfastMaxPrice: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check min", InValidInputMaxNum, () => isValidMaxNum(rootValue.breakfastMinPrice, rootValue.breakfastMaxPrice)),
      lunchMinPrice: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check min", InValidInputMinNum, () => isValidMinNum(rootValue.lunchMinPrice, rootValue.lunchMaxPrice)),
      lunchMaxPrice: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check min", InValidInputMaxNum, () => isValidMaxNum(rootValue.lunchMinPrice, rootValue.lunchMaxPrice)),
      dinnerMinPrice: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check min", InValidInputMinNum, () => isValidMinNum(rootValue.dinnerMinPrice, rootValue.dinnerMaxPrice)),
      dinnerMaxPrice: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check min", InValidInputMaxNum, () => isValidMaxNum(rootValue.dinnerMinPrice, rootValue.dinnerMaxPrice)),
      dailyMinEnergy: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check min", InValidInputMinNum, () => isValidMinNum(rootValue.dailyMinEnergy, rootValue.dailyMaxEnergy)),
      dailyMaxEnergy: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check max", InValidInputMaxNum, () => isValidMaxNum(rootValue.dailyMinEnergy, rootValue.dailyMaxEnergy)),
      dailyMinProtein: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check min", InValidInputMinNum, () => isValidMinNum(rootValue.dailyMinProtein, rootValue.dailyMaxProtein)),
      dailyMaxProtein: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check max", InValidInputMaxNum, () => isValidMaxNum(rootValue.dailyMinProtein, rootValue.dailyMaxProtein)),
      dailyMinSalt: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check min", InValidInputMinNum, () => isValidMinNum(rootValue.dailyMinSalt, rootValue.dailyMaxSalt)),
      dailyMaxSalt: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check max", InValidInputMaxNum, () => isValidMaxNum(rootValue.dailyMinSalt, rootValue.dailyMaxSalt)),
      dailyMinPotassium: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check min", InValidInputMinNum, () => isValidMinNum(rootValue.dailyMinPotassium, rootValue.dailyMaxPotassium)),
      dailyMaxPotassium: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check max", InValidInputMaxNum, () => isValidMaxNum(rootValue.dailyMinPotassium, rootValue.dailyMaxPotassium)),
      dailyMinPhosphorus: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check min", InValidInputMinNum, () => isValidMinNum(rootValue.dailyMinPhosphorus, rootValue.dailyMaxPhosphorus)),
      dailyMaxPhosphorus: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check max", InValidInputMaxNum, () => isValidMaxNum(rootValue.dailyMinPhosphorus, rootValue.dailyMaxPhosphorus)),
      eachBreakfastMinEnergy: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check min", InValidInputMinNum, () => isValidMinNum(rootValue.eachBreakfastMinEnergy, rootValue.eachBreakfastMaxEnergy)),
      eachBreakfastMaxEnergy: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check max", InValidInputMaxNum, () => isValidMaxNum(rootValue.eachBreakfastMinEnergy, rootValue.eachBreakfastMaxEnergy)),
      eachLunchMinEnergy: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check min", InValidInputMinNum, () => isValidMinNum(rootValue.eachLunchMinEnergy, rootValue.eachLunchMaxEnergy)),
      eachLunchMaxEnergy: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check max", InValidInputMaxNum, () => isValidMaxNum(rootValue.eachLunchMinEnergy, rootValue.eachLunchMaxEnergy)),
      eachDinnerMinEnergy: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check min", InValidInputMinNum, () => isValidMinNum(rootValue.eachDinnerMinEnergy, rootValue.eachDinnerMaxEnergy)),
      eachDinnerMaxEnergy: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check max", InValidInputMaxNum, () => isValidMaxNum(rootValue.eachDinnerMinEnergy, rootValue.eachDinnerMaxEnergy)),
      eachBreakfastMinProtein: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check min", InValidInputMinNum, () => isValidMinNum(rootValue.eachBreakfastMinProtein, rootValue.eachBreakfastMaxProtein)),
      eachBreakfastMaxProtein: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check max", InValidInputMaxNum, () => isValidMaxNum(rootValue.eachBreakfastMinProtein, rootValue.eachBreakfastMaxProtein)),
      eachLunchMinProtein: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check min", InValidInputMinNum, () => isValidMinNum(rootValue.eachLunchMinProtein, rootValue.eachLunchMaxProtein)),
      eachLunchMaxProtein: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check max", InValidInputMaxNum, () => isValidMaxNum(rootValue.eachLunchMinProtein, rootValue.eachLunchMaxProtein)),
      eachDinnerMinProtein: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check min", InValidInputMinNum, () => isValidMinNum(rootValue.eachDinnerMinProtein, rootValue.eachDinnerMaxProtein)),
      eachDinnerMaxProtein: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check max", InValidInputMaxNum, () => isValidMaxNum(rootValue.eachDinnerMinProtein, rootValue.eachDinnerMaxProtein)),
      eachBreakfastMinSalt: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check min", InValidInputMinNum, () => isValidMinNum(rootValue.eachBreakfastMinSalt, rootValue.eachBreakfastMaxSalt)),
      eachBreakfastMaxSalt: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check max", InValidInputMaxNum, () => isValidMaxNum(rootValue.eachBreakfastMinSalt, rootValue.eachBreakfastMaxSalt)),
      eachLunchMinSalt: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check min", InValidInputMinNum, () => isValidMinNum(rootValue.eachLunchMinSalt, rootValue.eachLunchMaxSalt)),
      eachLunchMaxSalt: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check max", InValidInputMaxNum, () => isValidMaxNum(rootValue.eachLunchMinSalt, rootValue.eachLunchMaxSalt)),
      eachDinnerMinSalt: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check min", InValidInputMinNum, () => isValidMinNum(rootValue.eachDinnerMinSalt, rootValue.eachDinnerMaxSalt)),
      eachDinnerMaxSalt: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check max", InValidInputMaxNum, () => isValidMaxNum(rootValue.eachDinnerMinSalt, rootValue.eachDinnerMaxSalt)),
      eachBreakfastMinPotassium: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check min", InValidInputMinNum, () => isValidMinNum(rootValue.eachBreakfastMinPotassium, rootValue.eachBreakfastMaxPotassium)),
      eachBreakfastMaxPotassium: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check max", InValidInputMaxNum, () => isValidMaxNum(rootValue.eachBreakfastMinPotassium, rootValue.eachBreakfastMaxPotassium)),
      eachLunchMinPotassium: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check min", InValidInputMinNum, () => isValidMinNum(rootValue.eachLunchMinPotassium, rootValue.eachLunchMaxPotassium)),
      eachLunchMaxPotassium: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check max", InValidInputMaxNum, () => isValidMaxNum(rootValue.eachLunchMinPotassium, rootValue.eachLunchMaxPotassium)),
      eachDinnerMinPotassium: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check min", InValidInputMinNum, () => isValidMinNum(rootValue.eachDinnerMinPotassium, rootValue.eachDinnerMaxPotassium)),
      eachDinnerMaxPotassium: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check max", InValidInputMaxNum, () => isValidMaxNum(rootValue.eachDinnerMinPotassium, rootValue.eachDinnerMaxPotassium)),
      eachBreakfastMinPhosphorus: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check min", InValidInputMinNum, () => isValidMinNum(rootValue.eachBreakfastMinPhosphorus, rootValue.eachBreakfastMaxPhosphorus)),
      eachBreakfastMaxPhosphorus: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check max", InValidInputMaxNum, () => isValidMaxNum(rootValue.eachBreakfastMinPhosphorus, rootValue.eachBreakfastMaxPhosphorus)),
      eachLunchMinPhosphorus: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check min", InValidInputMinNum, () => isValidMinNum(rootValue.eachLunchMinPhosphorus, rootValue.eachLunchMaxPhosphorus)),
      eachLunchMaxPhosphorus: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check max", InValidInputMaxNum, () => isValidMaxNum(rootValue.eachLunchMinPhosphorus, rootValue.eachLunchMaxPhosphorus)),
      eachDinnerMinPhosphorus: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check min", InValidInputMinNum, () => isValidMinNum(rootValue.eachDinnerMinPhosphorus, rootValue.eachDinnerMaxPhosphorus)),
      eachDinnerMaxPhosphorus: yup.number().required(mustBeInput).typeError(mustBeInput).min(0, '0以上の' + InValidInputNumber).test("check max", InValidInputMaxNum, () => isValidMaxNum(rootValue.eachDinnerMinPhosphorus, rootValue.eachDinnerMaxPhosphorus)),
    }),
  );