import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";

import { Control, Controller, Path } from "react-hook-form";

type Obj = {
  [x: string]: any
};

type ControlSelectProps<T extends Obj>  = {
  name: string,
  options: any   // TODO::label, valのみ受け付ける作りにする(優先上後回し)
  control: Control<T, any>,
  label?: string
  defaultValue?: string | number,
  width?: number
}

export const ControlSelect = <T extends Obj>(props: ControlSelectProps<T>) => {
  const {name, options, control, label='', defaultValue, width=200 } = props

  return (
    <Controller
      control={control}
      name={name as Path<T>}
      render={({ field, fieldState }) => (
        <FormControl fullWidth error={!!fieldState.error?.message} sx={{ minWidth: width }}>
          {label && <InputLabel id={name}>{label}</InputLabel>}
          <Select {...field} defaultValue={defaultValue}>
            {Object.values(options).map((item: any) => (
              <MenuItem value={item.value} key={item.value}>
                {item.title}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{fieldState.error?.message}</FormHelperText>
        </FormControl>          
      )}
    />
  );
};
