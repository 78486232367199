import Grid from "@mui/material/Grid";
import { memo } from "react";
import { LoadingSpinner } from "../../atoms/LoadingSpinner/LoadingSpinner"

export const LoadingTemplate = memo(() => {
  return (
    <>
      <Grid container alignItems='center' justifyContent='center' direction="column" data-testid='template'>
        <LoadingSpinner />
      </Grid>
    </>
  );
});