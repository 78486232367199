import { FC } from "react";

type Props = {
  value: number;
  index: number;
  children: React.ReactElement;
};

export const TabPanel: FC<Props> = ({ value, index, children }) => {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <>{children}</>}
    </div>
  );
};
