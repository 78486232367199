import { memo, FC } from "react";
import firebase from "firebase/compat/app";
import { EmailAuthProvider } from "firebase/auth";
import "firebase/compat/auth";
import Grid from "@mui/material/Grid";
import StyledFirebaseAuth from "../../components/organisms/FirebaseUI/StyledFirebaseAuth";

export const LoginPage: FC = memo(() => {
  const uiConfig: firebaseui.auth.Config = {
    signInFlow: "popup",
    signInSuccessUrl: "/",
    signInOptions: [
      {
        provider: EmailAuthProvider.PROVIDER_ID,
        requireDisplayName: false,
        disableSignUp: { status: true },
      },
    ],
  };

  return (
    <Grid
      container
      sx={{
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid sx={{ 
        width: "100%",
        position: "absolute",
        top: "40%",
        margin: "-25px 0 0 -25px",
      }}>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} /> 
      </Grid>
    </Grid>
  );
});
