import {yupResolver} from "@hookform/resolvers/yup";
import {useCallback, useEffect} from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {
  APIPathGetPresetConditionsById,
  APIPathGetPresetConditionsList,
  APIPathPostUpdatePresetConditions
} from "../../endpoint";
import {usePost} from "../../hooks/usePost";
import {ConditionSchema} from "../../lib/Validation/yup/conditionSchema";
import {ConditionFormValues} from "../../types/condition";
import {useSnackbar} from "../../components/organisms/SnackProvider";
import {GetPresetConditionById, UpdatePresetConditionsType} from "../../types/presetCondition";
import {useFetch} from "../../hooks/useFetch";


export const usePresetConditionsForm = ((
    presetConditionsId: string |  undefined,
) => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const { data, isLoading, hasError, error } = useFetch<GetPresetConditionById>({
    url: APIPathGetPresetConditionsById(presetConditionsId),
  })
  if (hasError) {
    showSnackbar(`データ取得に失敗しました: ${error.message}`, "error");
  }

  const formMethods = useForm<any>({
    mode: "onChange",
    // NOTE:: uncotrolled componentのため、defaultValuesを設定する
    defaultValues: {
      id: '',
      presetConditionName: '',
      presetConditionCode: '',
      priceType: '',
      breakfastMinPrice: '',
      breakfastMaxPrice: '',
      lunchMinPrice: '',
      lunchMaxPrice: '',
      dinnerMinPrice: '',
      dinnerMaxPrice: '',
      dailyMinEnergy: '',
      dailyMaxEnergy: '',
      dailyMinProtein: '',
      dailyMaxProtein: '',
      dailyMinSalt: '',
      dailyMaxSalt: '',
      dailyMinPotassium: '',
      dailyMaxPotassium: '',
      dailyMinPhosphorus: '',
      dailyMaxPhosphorus: '',
      eachBreakfastMinEnergy: '',
      eachBreakfastMaxEnergy: '',
      eachLunchMinEnergy: '',
      eachLunchMaxEnergy: '',
      eachDinnerMinEnergy: '',
      eachDinnerMaxEnergy: '',
      eachBreakfastMinProtein: '',
      eachBreakfastMaxProtein: '',
      eachLunchMinProtein: '',
      eachLunchMaxProtein: '',
      eachDinnerMinProtein: '',
      eachDinnerMaxProtein: '',
      eachBreakfastMinSalt: '',
      eachBreakfastMaxSalt: '',
      eachLunchMinSalt: '',
      eachLunchMaxSalt: '',
      eachDinnerMinSalt: '',
      eachDinnerMaxSalt: '',
      eachBreakfastMinPotassium: '',
      eachBreakfastMaxPotassium: '',
      eachLunchMinPotassium: '',
      eachLunchMaxPotassium: '',
      eachDinnerMinPotassium: '',
      eachDinnerMaxPotassium: '',
      eachBreakfastMinPhosphorus: '',
      eachBreakfastMaxPhosphorus: '',
      eachLunchMinPhosphorus: '',
      eachLunchMaxPhosphorus: '',
      eachDinnerMinPhosphorus: '',
      eachDinnerMaxPhosphorus: '',
      optionSpecificConditions: '',
      lastMonthMenuJobId: '',
      nattoKoteiYoubi: '',
      yoshokuKoteiYoubi1: '',
      yoshokuKoteiYoubi2: ''
    },
    resolver: yupResolver(ConditionSchema),
  });

  useEffect(() => {
    if (data == null) return;
    // 暫定対応(サーバーと同じ命名したほうが良い)
    const mappingData: ConditionFormValues = {
      id: data.id,
      presetConditionName: data.name,
      presetConditionCode: data.code,
      priceType: data.price_type,
      breakfastMinPrice: data.breakfast_price_min,
      breakfastMaxPrice: data.breakfast_price_max,
      lunchMinPrice: data.lunch_price_min,
      lunchMaxPrice: data.lunch_price_max,
      dinnerMinPrice: data.dinner_price_min,
      dinnerMaxPrice: data.dinner_price_max,
      dailyMinEnergy: data.energy_min,
      dailyMaxEnergy: data.energy_max,
      dailyMinProtein: data.protein_min,
      dailyMaxProtein: data.protein_max,
      dailyMinSalt: data.salt_min,
      dailyMaxSalt: data.salt_max,
      dailyMinPotassium: data.potassium_min,
      dailyMaxPotassium: data.potassium_max,
      dailyMinPhosphorus: data.phosphorus_min,
      dailyMaxPhosphorus: data.phosphorus_max,
      eachBreakfastMinEnergy: data.energy_breakfast_min,
      eachBreakfastMaxEnergy: data.energy_breakfast_max,
      eachLunchMinEnergy: data.energy_lunch_min,
      eachLunchMaxEnergy: data.energy_lunch_max,
      eachDinnerMinEnergy: data.energy_dinner_min,
      eachDinnerMaxEnergy: data.energy_dinner_max,
      eachBreakfastMinProtein: data.protein_breakfast_min,
      eachBreakfastMaxProtein: data.protein_breakfast_max,
      eachLunchMinProtein: data.protein_lunch_min,
      eachLunchMaxProtein: data.protein_lunch_max,
      eachDinnerMinProtein: data.protein_dinner_min,
      eachDinnerMaxProtein: data.protein_dinner_max,
      eachBreakfastMinSalt: data.salt_breakfast_min,
      eachBreakfastMaxSalt: data.salt_breakfast_max,
      eachLunchMinSalt: data.salt_lunch_min,
      eachLunchMaxSalt: data.salt_lunch_max,
      eachDinnerMinSalt: data.salt_dinner_min,
      eachDinnerMaxSalt: data.salt_dinner_max,
      eachBreakfastMinPotassium: data.potassium_breakfast_min,
      eachBreakfastMaxPotassium: data.potassium_breakfast_max,
      eachLunchMinPotassium: data.potassium_lunch_min,
      eachLunchMaxPotassium: data.potassium_lunch_max,
      eachDinnerMinPotassium: data.potassium_dinner_min,
      eachDinnerMaxPotassium: data.potassium_dinner_max,
      eachBreakfastMinPhosphorus: data.phosphorus_breakfast_min,
      eachBreakfastMaxPhosphorus: data.phosphorus_breakfast_max,
      eachLunchMinPhosphorus: data.phosphorus_lunch_min,
      eachLunchMaxPhosphorus: data.phosphorus_lunch_max,
      eachDinnerMinPhosphorus: data.phosphorus_dinner_min,
      eachDinnerMaxPhosphorus: data.phosphorus_dinner_max,
      optionSpecificConditions: data.option_specific_conditions,
      lastMonthMenuJobId: data.last_month_menu_job_id,
      nattoKoteiYoubi: data.natto_kotei_youbi,
      yoshokuKoteiYoubi1: data.yoshoku_kotei_youbi_1,
      yoshokuKoteiYoubi2: data.yoshoku_kotei_youbi_2
    };
    formMethods.reset(mappingData);
  }, [data, formMethods.reset]); // eslint-disable-line react-hooks/exhaustive-deps

  const [ doPost ] = usePost({
    method: 'put',
    url: APIPathPostUpdatePresetConditions,
  });

  const handleUpdate = useCallback(() => {
    const values = formMethods.getValues()
    const postData: UpdatePresetConditionsType = {
      id: values.id ?? '',
      name: values.presetConditionName,
      code: values.presetConditionCode,
      price_type: values.priceType,
      breakfast_price_min: values.breakfastMinPrice,
      breakfast_price_max: values.breakfastMaxPrice,
      lunch_price_min: values.lunchMinPrice,
      lunch_price_max: values.lunchMaxPrice,
      dinner_price_min: values.dinnerMinPrice,
      dinner_price_max: values.dinnerMaxPrice,
      energy_min: values.dailyMinEnergy,
      energy_max: values.dailyMaxEnergy,
      // 入力項目がないので、固定値で渡す
      // https://jdsc.slack.com/archives/C0395V6U353/p1674449885316459
      fat_min: 0,
      fat_max: 1,
      carb_min: 0,
      carb_max: 1,
      protein_min: values.dailyMinProtein,
      protein_max: values.dailyMaxProtein,
      salt_min: values.dailyMinSalt,
      salt_max: values.dailyMaxSalt,
      potassium_min: values.dailyMinPotassium,
      potassium_max: values.dailyMaxPotassium,
      phosphorus_min: values.dailyMinPhosphorus,
      phosphorus_max: values.dailyMaxPhosphorus,
      energy_breakfast_min: values.eachBreakfastMinEnergy,
      energy_breakfast_max: values.eachBreakfastMaxEnergy,
      energy_lunch_min: values.eachLunchMinEnergy,
      energy_lunch_max: values.eachLunchMaxEnergy,
      energy_dinner_min: values.eachDinnerMinEnergy,
      energy_dinner_max: values.eachDinnerMaxEnergy,
      protein_breakfast_min: values.eachBreakfastMinProtein,
      protein_breakfast_max: values.eachBreakfastMaxProtein,
      protein_lunch_min: values.eachLunchMinProtein,
      protein_lunch_max: values.eachLunchMaxProtein,
      protein_dinner_min: values.eachDinnerMinProtein,
      protein_dinner_max: values.eachDinnerMaxProtein,
      salt_breakfast_min: values.eachBreakfastMinSalt,
      salt_breakfast_max: values.eachBreakfastMaxSalt,
      salt_lunch_min: values.eachLunchMinSalt,
      salt_lunch_max: values.eachLunchMaxSalt,
      salt_dinner_min: values.eachDinnerMinSalt,
      salt_dinner_max: values.eachDinnerMaxSalt,
      potassium_breakfast_min: values.eachBreakfastMinPotassium,
      potassium_breakfast_max: values.eachBreakfastMaxPotassium,
      potassium_lunch_min: values.eachLunchMinPotassium,
      potassium_lunch_max: values.eachLunchMaxPotassium,
      potassium_dinner_min: values.eachDinnerMinPotassium,
      potassium_dinner_max: values.eachDinnerMaxPotassium,
      phosphorus_breakfast_min: values.eachBreakfastMinPhosphorus,
      phosphorus_breakfast_max: values.eachBreakfastMaxPhosphorus,
      phosphorus_lunch_min: values.eachLunchMinPhosphorus,
      phosphorus_lunch_max: values.eachLunchMaxPhosphorus,
      phosphorus_dinner_min: values.eachDinnerMinPhosphorus,
      phosphorus_dinner_max: values.eachDinnerMaxPhosphorus,
      option_specific_conditions: values.optionSpecificConditions,
      last_month_menu_job_id: values.lastMonthMenuJobId,
      natto_kotei_youbi: values.nattoKoteiYoubi,
      yoshoku_kotei_youbi_1: values.yoshokuKoteiYoubi1,
      yoshoku_kotei_youbi_2: values.yoshokuKoteiYoubi2,
    };

    doPost({
      params: postData,
      onSuccess: () => {
        navigate(APIPathGetPresetConditionsList);
        showSnackbar(`プリセット制約条件を作成しました code: ${postData.code}`, 'success');
      },
      onError: (err) => {
        // TODO: プリセット条件コード削除後にこのコードを削除する
        if (err.response?.status === 400) {
          const detail = (err.response?.data as any).detail;
          showSnackbar(`更新に失敗しました: ${detail}`, "error");
        } else {
          showSnackbar(`更新に失敗しました: ${err.message}`, "error");
        }
      }
    });
  }, [doPost, navigate, showSnackbar, formMethods]);

  return {
    data,
    isLoading,
    formMethods,
    handleUpdate,
  };
});
