import TableCell from "@mui/material/TableCell";
import Grid from "@mui/material/Grid";
import { memo, FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { usePost } from "../../../../hooks/usePost";
import { DeleteButton } from "../../../atoms/deleteButton";
import { UpdateButton } from "../../../atoms/updateButton";
import { useSnackbar } from "../../SnackProvider";
import {APIPathGetPresetConditionsById} from "../../../../endpoint";

type Props = {
  presetConditionsId: string;
};

export const PresetConditionsActionCell: FC<Props> = memo(({ presetConditionsId }) => {
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [ doPost, isLoading ] = usePost({
    method: 'delete',
    url: APIPathGetPresetConditionsById(presetConditionsId),
  })

  const Delete = useCallback(() => {
    doPost({
      params: {
        presetConditionsId
      },
      onSuccess: () => {
        window.location.reload();
        showSnackbar("削除しました", "success");
      },
      onError: (err) => {
        showSnackbar(`削除に失敗しました: ${err.message}`, "error");
      }
    })
  }, [presetConditionsId, showSnackbar, doPost]);

  return (
    <>
      <TableCell
        variant="body"
        padding={"normal"}
        sx={{
          textAlign: "center",
        }}
      >
        {/* 編集と削除がある */}
        <Grid
          sx={{
            display: "flex",
            flexWrap: "nowrap",
            justifyContent: "center",
            columnGap: (theme) => theme.spacing(3),
          }}
        >
          <UpdateButton
            text={"編集する"}
            onPush={() => {
              navigate(APIPathGetPresetConditionsById(presetConditionsId));
            }}
          />
          <DeleteButton
            text={"削除する"}
            onPush={() => Delete()}
          />
        </Grid>
      </TableCell>
    </>
  );
});
