import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { FC } from "react";

type TableHeaderProps = {
  headCells: string[];
};

export const TableHeader: FC<TableHeaderProps> = ({ headCells }) => {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, idx: number) => (
          <TableCell
            key={idx}
            variant="head"
            padding={"normal"}
            sx={{
              backgroundColor: (theme) => theme.palette.primary.main,
              color: (theme) => theme.palette.primary.contrastText,
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            {headCell}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
