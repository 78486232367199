import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/system"
import { InsufficientInfo, SortedColorInfo, SortedIncredient, SortedIncredientInfo, SortedNutritionList, SortedNutritionInfo } from "../../../../types/menu"
import { sortColor, sortIncredient, sortNutrition } from "../../../../pages/menu/sortInsufficient"
import { changeStrToDateFormat } from "../../../../util/date";
import { generateMenuDetailPath } from "../../../../RouterConfig";

type MenuEvaluationProps = {
  insufficient: InsufficientInfo,
  menuId: string,
}

export const MenuEvaluation = (props: MenuEvaluationProps) => {
  const { insufficient, menuId } = props;
  // 上書きされている関係上
  const primaryColor ='#2196f3';

  return (
    <Grid sx={{  marginLeft: 10, marginRight:10 }}>
      <Grid padding={2}>
        <Typography variant="h4" fontWeight="bold" borderBottom={"1px solid silver"}>逸脱条件一覧</Typography>
        <Grid margin={4}>
          {
            insufficient.all.length > 0 ? (
              insufficient.all.map((val: any, idx: number) => (
                <Box marginLeft={5}> 
                  {val}
                </Box>
              ))
            ): (
              <Typography>該当のデータは存在しません</Typography>
            )
          }
        </Grid>
      </Grid>
    </Grid>
  )
}