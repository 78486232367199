import { memo, FC } from "react";
import { Route, Routes, generatePath } from "react-router-dom";
import { CreatePresetConditionsPage } from "./pages/createPresetConditions";
import { DishesDetailPage } from "./pages/dishDetail";
import { DishesPage } from "./pages/dishes";
import { EditPresetConditionsPage } from "./pages/editPresetConditions";
import { LoginPage } from "./pages/login";
import { LogoutPage } from "./pages/logout";
import { MembershipTypePage } from "./pages/menbershipType";
import { MenuDetailPage } from "./pages/menuDetail";
import { ManualDishesPage } from "./pages/manualDishes";
import { MenuPage } from "./pages/menu";
import { MenusPage } from "./pages/menus";
import { PresetConditionsPage } from "./pages/presetConditions";
import { ErrorPage } from "./pages/404";
import { EditConditionsPage } from "./pages/editConditions";
import { MenuCreatePage } from "./pages/menuCreate";
import { AppTemplate } from "./components/template/AppTemplate";
import { DataImport } from "./pages/dataImport";
import MasterPage from "./pages/master";


const menuDetailPath = "/menu/:menuId/detail/:targetDay"
export const masterPath = "/master"
export const generateMenuDetailPath = (menuId: string, targetDay: string) => {
  return generatePath(menuDetailPath, {menuId, targetDay});
};

export const AppRouter: FC = memo(() => {
  return (
    <>
      <Routes>
        <Route path="/" element={<AppTemplate />} >
          <Route index element={<DishesPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/data_import" element={<DataImport />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="/dishes" element={<DishesPage />} />
          <Route path="/dishes/:dishId" element={<DishesDetailPage />} />
          <Route path="/preset_conditions" element={<PresetConditionsPage />} />
          <Route path="/preset_conditions/create" element={<CreatePresetConditionsPage />} />
          <Route path="/preset_conditions/:presetConditionsId" element={<EditPresetConditionsPage />} />
          <Route path="/membership_type" element={<MembershipTypePage />} />
          <Route path="/membership_type/:membershipTypeId/menu" element={<MenusPage />} />
          <Route path="/membership_type/:membershipTypeId/menu/:targetMonth/create" element={<MenuCreatePage />} />
          <Route path="/menu/:menuId" element={<MenuPage />} />
          <Route path="/menu/:menuId/conditions" element={<EditConditionsPage />} />
          <Route path={menuDetailPath} element={<MenuDetailPage />} />
          <Route path="/menu/:menuId/manual_create/:targetDay/:mealType" element={<ManualDishesPage />} />
          <Route path={masterPath} element={<MasterPage />} />
          <Route path="*" element={<ErrorPage statusCode={404} />} />
        </Route>
      </Routes>
    </>
  );
});
