import Grid from "@mui/material/Grid";
import { FC, ReactElement } from "react";
import { ColumnTitle } from "../../atoms/columnTitle";

type Props = {
  sectionTitle: string;
  children: ReactElement;
};

export const DishDetailSection: FC<Props> = ({ sectionTitle, children }) => {
  return (
    <>
      <Grid>
        <Grid
          sx={{
            marginY: (theme) => theme.spacing(2),
          }}
        >
          <ColumnTitle title={sectionTitle} />
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            columnGap: (theme) => theme.spacing(3),
          }}
        >
          {children}
        </Grid>
      </Grid>
    </>
  );
};
