import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { APIDeleteMenuManual, APIPostMenuManual, APIPutMenuManual } from "../../endpoint";
import { usePost } from "../../hooks/usePost";
import { MealType } from "../../types/menu";
import { ManualMeal, ManualMealDish } from "../../types/menuManual";
import { useSnackbar } from "../../components/organisms/SnackProvider";

type PostVal  = {
  meal_type: "breakfast" | "lunch" | "dinner";
  target_day: string,
  dish_source_ids: string[],
};

type PutVal  = {
  dish_source_ids: string[],
};

export const usePostManual = (
  menuId: string, 
  manualDishes: ManualMealDish[] | null,
  manualMeal: ManualMeal | undefined | null,
  targetDay: string, 
  mealType: MealType,
) => {
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const initialValues: PostVal = {
    target_day: "",
    meal_type: "breakfast",
    dish_source_ids: [],
  };

  const formMethods = useForm<PostVal>({
    mode: "onChange",
    defaultValues: initialValues,
  });
  const [ doPost, isLoading ] = usePost({
    method: manualMeal ? 'put' : 'post',
    url: manualMeal ? APIPutMenuManual(menuId, manualMeal.id) : APIPostMenuManual(menuId),
  });

  const handleSubmit = useCallback(() => {
    if (!manualDishes) return

    let sourceUIds: string[] = [];
    for (const item of manualDishes) {
      sourceUIds.push(item.source_uid);
    }

    const postData: PostVal = {
      meal_type: mealType,
      target_day: targetDay,
      dish_source_ids: sourceUIds,
    }

    const putData: PutVal = {
      dish_source_ids: sourceUIds,
    }

    doPost({
      params: (manualMeal !== undefined && manualMeal !== null) ? putData : postData,
      onSuccess: () => {
          showSnackbar(`マニュアル条件を作成しました`, 'success');
          navigate(`/menu/${menuId}`);
      },
      onError: (err: Error) => {
          showSnackbar(`更新に失敗しました: ${err.message}`, "error");
      }
    });
  },[manualMeal, manualDishes]) // eslint-disable-line
  
  const [ doDelete, isLoadingDelete ] = usePost({
    method: 'delete',
    url: APIDeleteMenuManual(menuId, manualMeal?.id ?? ''),
  });

  const handleDelete = useCallback(() => {
    if (manualMeal === undefined) return;

    doDelete({
      onSuccess: () => {
        showSnackbar(`マニュアル条件を条件を削除しました`, 'success');
      },
      onError: (err: Error) => {
        showSnackbar(`削除に失敗しました: ${err.message}`, "error");
      }
    });

    navigate(`/menu/${menuId}`);
  },[manualMeal, manualDishes]) // eslint-disable-line

  return {
    formMethods,
    handleSubmit,
    isLoading,
    handleDelete,
    isLoadingDelete,
  }
}
