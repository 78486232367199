import Modal from "@mui/material/Modal";
import { Box } from "@mui/system";
import { ReactNode } from "react";

type ModalProps = {
  open: boolean,
  handleClose: () => void,
  children: ReactNode,
  width?: number | string,
}

export const ModalMUI = (props: ModalProps) => {
  const { open, handleClose, children, width=''} = props;

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: width,
    maxHeight: "100%", 
    overflow: "auto",
    backgroundColor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {children}
      </Box>
    </Modal>
  )
}

