import {AxiosError} from "axios";
import {useCallback, useEffect, useState} from "react";
import api from "../../const/api";
import {APIPathGetMenuForLastMonthMenu} from "../../endpoint";
import {MenuListType} from "../../types/menus";
import {useAuthContext} from "../../components/organisms/AuthProvider";
import {User} from "firebase/auth";

export const useLastMonthMenuList = () => {
  const [menuVariationList, setMenuVariationList] = useState([] as MenuListType[]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError | null>(null);
  const authContext = useAuthContext();
  const isLoadingUser = authContext.isLoadingUser ?? true;
  const user = authContext.user ?? null;

  // TODO:: usefetchをより抽象化して使いやすくする or swrを入れる
  // handleClickが押されてMonthが変更 → fetchすることで初めてページが送られる作りなためuseEffectの副作用を使用していない
  const handleChangePage = useCallback(async (user: User) => {
    try {
      setIsLoading(true);
      const token = await user.getIdToken();
      const result = await api.get(APIPathGetMenuForLastMonthMenu, token);
      const data : any = {};
      data[""] = {"title" : "（設定なし）", "value" : ""};
      data["manual"] = {"title" : "手動設定（アップロードした先月末データ）", "value" : "manual"};
      for (const value of result.data) {
        data[value.id] = {"title" : value.name, "value" : value.id};
      }
      setMenuVariationList(data);
    } catch (e: unknown) {
      setError(error as AxiosError);
    } finally {
      setIsLoading(false);
    }
  },[]);  // eslint-disable-line
  
  useEffect(() => {
    if (isLoadingUser) return;
    if (user === null) return;

    handleChangePage(user);
  }, [handleChangePage]);

  return {
    isLoading,
    error,
    menuVariationList,
  }
};
