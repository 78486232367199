import Grid from "@mui/material/Grid";
import { FC, memo } from "react";
import { PageTitle } from "../../components/atoms/pageTitle";
import { DishesTable } from "../../components/organisms/Dish/DishesTable";
import { SearchForm } from "../../components/organisms/Dish/SearchForm.tsx/SearchForm";
import { FormProvider } from "react-hook-form";
import { useDishes } from "./useDishes.hooks";
import { LoadingTemplate } from "../../components/template/LoadingTemplate/LoadingTemplate";


export const DishesPage: FC = memo(() => {
  const { dishData, page, fetchNum, totalCount, formMethods,
          loading, handleSearch, handleChangePage, handleChangeRowsPerPage} = useDishes();

  if (loading) {
    return (
      <>
        <PageTitle title={"商品一覧"} />
        <LoadingTemplate />
      </>
    )
  }

  return (
    <>
      <PageTitle title={"商品一覧"} />
      <Grid
        container
        sx={{
          paddingTop: (theme) => theme.spacing(5),
          paddingX: (theme) => theme.spacing(3),
        }}
      >
        <FormProvider {...formMethods}>
          <SearchForm handleSubmit={formMethods.handleSubmit(handleSearch)}/>
        </FormProvider>
      </Grid>

      <Grid
          container
          sx={{
            paddingTop: (theme) => theme.spacing(5),
            paddingX: (theme) => theme.spacing(3),
          }}
      >
          <DishesTable 
            dishData={dishData}
            page={page}
            totalCount={totalCount}
            fetchNum={fetchNum}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
      </Grid>
    </>
  );
});
