import { AlertColor } from "@mui/material";
import { useCallback, useMemo } from "react";
import { ReactNode, FC, useState, createContext, useContext } from "react";
import { SnackBar } from "../../atoms/errorSnack";

export type SnackContextProps = {
  message: string;
  severity: AlertColor;
  showSnackbar: (message: string, severity: AlertColor) => void;
};

type Props = {
  children: ReactNode;
};

export const SnackContext = createContext<SnackContextProps>({
  message: "",
  severity: "error",
  showSnackbar: (_message: string, _severity: AlertColor) => {},
});

export const SnackProvider: FC<Props> = ({ children }) => {
  const context = useContext(SnackContext);
  const [message, setMessage] = useState(context.message);
  const [severity, setSeverity] = useState(context.severity);

  const newContext: SnackContextProps = useMemo(
    () => ({
      message,
      severity,
      showSnackbar: (message: string, severity: AlertColor) => {
        setMessage(message);
        setSeverity(severity);
      },
    }),
    [message, severity, setMessage, setSeverity],
  );

  const handleClose = useCallback(() => {
    setMessage("");
  }, [setMessage]);

  return (
    <SnackContext.Provider value={newContext}>
      {children}
      <SnackBar
        isOpen={newContext.message !== ""}
        message={newContext.message}
        severity={newContext.severity}
        onClose={handleClose}
      />
    </SnackContext.Provider>
  );
};

export function useSnackbar(): SnackContextProps {
  return useContext(SnackContext);
}
