import axios from "axios";

const API_HOST =  process.env.REACT_APP_BACKEND_URL
axios.defaults.baseURL = API_HOST || "";

// dishes
export const APIGetDishes = (page: number, fetchNum: number, params?: string | null) => {
  if (params == null) return `/dishes?page=${page}&fetch_num=${fetchNum}`;
  return `/dishes?page=${page}&fetch_num=${fetchNum}&${params}`;
} 
export const APIGetDishesByVal = (params: string) => `/search_dishes?${params}`;
export const APIPathGetDishById = (dishId: string | undefined) => `/dishes/${dishId}`;

// preset
export const APIPathGetPresetConditionsList = `/preset_conditions`;
export const APIPathPostCreatePresetConditions = "/preset_conditions/create";
export const APIPathPostUpdatePresetConditions = "/preset_conditions/update";
export const APIPathGetPresetConditionsById = (presetConditionsId: string | undefined)  => `/preset_conditions/${presetConditionsId}`;

// menu
export const APIPathGetMembershipTypes = `/membership_type`;
export const APIPathGetMenusByMembershipId = (membershipTypeId: string | undefined, targetMonth: Date) => `/membership_type/${membershipTypeId}/menu/${targetMonth.toISOString().split('T')[0]}`;
export const APIPostCreateMenu = (membershipTypeId: string | undefined) => `/membership_type/${membershipTypeId}/menu/create`;
export const APIPathGetMenuById = (menuId: string | undefined) => `/menu/${menuId}`;
export const APIPathGetMenuByIdAndTargetDate = (menuId: string | undefined, targetDay: string) => `/menu/${menuId}/detail/${targetDay}`;

// manual meal
export const APIGetManualMenuById = (menuId: string, manualId: string) => {
  return API_HOST + `/menu/${menuId}/manual_meal/${manualId}`
}
export const APIPostMenuManual = (menuId: string) => `/menu/${menuId}/manual_meal/create`;
export const APIPutMenuManual = (menuId: string, manualMealId: string) => `/menu/${menuId}/manual_meal/${manualMealId}/update`;
export const APIDeleteMenuManual = (menuId: string, manualMealId: string) => `/menu/${menuId}/manual_meal/${manualMealId}/delete`;

export const APIPathPostKickMakingMenuJob = (menuId: string | undefined) => `/kick_making_manu_job/${menuId}`;

// conditions
export const APIPathGetConditionsByMenuId = (menuId: string | undefined) => `/menu/${menuId}/conditions`;
export const APIPathPutUpdateConditions = (menuId: string | undefined) => `menu/${menuId}/conditions/update`;
export const APIPathGetMenuForLastMonthMenu = `/menus`;

// master
export const APIPathPostDishMaster = '/master/upload';
export const APIPathPostDishImgMaster = '/master/upload/dish-images';
