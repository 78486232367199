
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Grid from "@mui/material/Grid";
import { FC } from "react";

type InputType = 'text' | 'number' | 'email' | 'password';
type Variant = 'outlined' | 'filled' | 'standard';
type Props = {
  type: InputType
  formTitle: string;
  value: string | number;
  variant?: Variant
  defaultValue?: string | number
  placeholder?: string
  width?: number 
  disabled?: boolean
  handleChange?:  (event: React.ChangeEvent<HTMLInputElement>) => void
  handleBlur?:  () => void
  multiline?: boolean
};

export const Input: FC<Props> = ({ 
  type,
  formTitle, 
  value,
  variant = 'outlined',
  defaultValue,
  placeholder = '',
  disabled = false,
  width = null,
  handleChange = () => {},
  handleBlur = () => {},
  multiline = false
}) => {
  return (
    <Grid>
      <InputLabel sx={{ marginY: (theme) => theme.spacing(1), fontWeight: "bold" }}>
        {formTitle}
      </InputLabel>
      <TextField
        type={type}
        variant={variant}
        value={value}
        defaultValue={defaultValue}
        placeholder={placeholder}
        disabled={disabled}
        margin="normal"
        multiline={multiline}
        sx={{
          display: "flex",
          minWidth: "16vw",
          maxWidth: "84vw",
          width: width ? width + 'ch': null,
        }}
        onChange={handleChange}
        onBlur={handleBlur}
        data-testid='test'
      />
    </Grid>
  );
};
