import Alert, { AlertColor } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { FC } from "react";

type Props = {
  isOpen: boolean;
  message: string;
  severity: AlertColor;
  onClose?: () => void;
};

export const SnackBar: FC<Props> = ({ isOpen, message, severity, onClose }) => {
  return (
    <Snackbar anchorOrigin={{  vertical: "top", horizontal: "right" }} open={isOpen} onClose={onClose} autoHideDuration={5000}>
      <Alert severity={severity}>{message}</Alert>
    </Snackbar>
  );
};
