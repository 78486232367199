export class MenuInfo {
  constructor(
    public energy: number,
    public price: number,
    public protein: number,
    public carb: number,
    public fat: number,
    public salt: number,
    public potassium: number,
    public phosphorus: number
  ) { }


  //  上記の必要な情報をstringにして返す
  getAllToString() {
    const text = `エネルギー: ${Math.round(this.energy * 1000) / 10} kcal<br>価格: ${Math.round(this.price * 10) / 10} 円\nたんぱく質 : ${Math.round(this.protein * 10) / 10} g\n脂質: ${Math.round(this.fat * 10) / 10} g\n炭水化物 : ${Math.round(this.carb * 10) / 10} g\n食塩相当量 : ${Math.round(this.salt * 10) / 10} g\nカリウム : ${Math.round(this.potassium * 10) / 10} mg\nリン : ${Math.round(this.phosphorus * 10) / 10} mg`;

    return text;
  }
}
