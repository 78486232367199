import Typography from "@mui/material/Typography";
import { FC, memo } from "react";

type Props = {
  title: string;
};

export const PageTitle: FC<Props> = memo(({ title }) => {
  return (
    <Typography
      sx={{
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: "700",
        letterSpacing: "0.5px",
        lineHeight: "35px",
      }}
    >
      {title}
    </Typography>
  );
});
