import Button from "@mui/material/Button";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  url: string;
  text: string;
  disabled?: boolean,
};

export const LinkButton: FC<Props> = ({ url, text, disabled=false }) => {
  const navigate = useNavigate();

  return (
    <Button variant="outlined" onClick={() => navigate(url)} color={"inherit"} disabled={disabled}>
      {text}
    </Button>
  );
};
