export type OptionSpecificCondition = {
  formName: string,
  ConditionTitle: string
}

export const OptionSpecificConditionsList: OptionSpecificCondition[] = [
  // 箸休め : 必須要件ではないのでフロントからは一旦入力させていない
  // {
  //   formName: "morningEntremets",
  //   ConditionTitle: "朝食に箸休めを含める"
  // },
  // {
  //   formName: "lunchDinnerEntremets",
  //   ConditionTitle: "昼食、夕食に箸休めを含める"
  // },
  // その他条件
  {
    formName: "sameTastePerDay",
    ConditionTitle: "1日の「同じ味付けの商品」は、1商品まで"
  },
  {
    formName: "similerShepePerDish",
    ConditionTitle: "1食の「似た形を含む商品」は、1商品まで"
  },
  {
    formName: "sameRecipePerDish",
    ConditionTitle: "1食の「同じ調理法の商品」は、1商品まで"
  },
  {
    formName: "carrotLimit",
    ConditionTitle: "「人参を含む商品」は、1日の5商品まで、1食3商品まで"
  },
];
