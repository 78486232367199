import { MenuInfo } from "./MenuInfo";

export type MenuType = "breakfast" | "lunch" | "dinner";

export class Menu {
  constructor(
    public menuType: MenuType, 
    public image_url: string, 
    public is_manual: boolean,
    public manual_meal_id: string,
    public menuInfo: MenuInfo, 
    public menu: string[]
  ) {}
}
