import { FC, memo, Suspense } from "react";
import { AppRouter } from "./RouterConfig";
import { LoadingTemplate } from "./components/template/LoadingTemplate/LoadingTemplate";
import { ContextWrapper } from "./context/ContextWrapper";

export const App: FC = memo(() => {
  return (
    <div className="App">
      <ContextWrapper>
        <Suspense fallback={<LoadingTemplate />}>
          <AppRouter />
        </Suspense>
      </ContextWrapper>
    </div>
  );
});
