import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { memo, FC, useContext } from "react";
import List from "@mui/material/List";
import { SideBarItem } from "../../../organisms/SideBarItem";
import { SideBarSection } from "./sidebarSection";
import { sideBarContext } from "../state";
import { masterPath } from "../../../../RouterConfig";

export const AppSideBar: FC = memo(() => {
  const { isOpen, setIsOpen } = useContext(sideBarContext);

  return (
    <Drawer anchor="left" open={isOpen}>
      <IconButton onClick={() => setIsOpen(false)}>
        <ChevronLeftIcon />
      </IconButton>
      <List>
        <SideBarSection title="商品">
          <SideBarItem text="商品一覧" url="/dishes" />
        </SideBarSection>
        <SideBarSection title="生成条件">
          <SideBarItem text="プリセット条件一覧" url="/preset_conditions" />
        </SideBarSection>
        <SideBarSection title="献立">
          <SideBarItem text="会員種別一覧" url="/membership_type" />
        </SideBarSection>
        <SideBarSection title="全般">
          <SideBarItem text="商品データ・前月末データ取込" url="/data_import" />
        </SideBarSection>
        {/* <SideBarSection title="データ">
          <SideBarItem text="マスタ管理" url={masterPath} />
        </SideBarSection> */}
        <SideBarSection title="その他">
          <SideBarItem text="ログアウトする" url="/logout" />
        </SideBarSection>
      </List>
    </Drawer>
  );
});
