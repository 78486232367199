import axios from "axios";
import firebase from 'firebase/compat/app';
import {useSnackbar} from "../../components/organisms/SnackProvider";

export const useExecPostProcess = () => {
  const { showSnackbar } = useSnackbar();

  const handleClickPostProcess = async (latestJobId: string | null) => {
    if (latestJobId === null) return;
    const URL = `/postprocess/${latestJobId}`

    firebase.auth().onAuthStateChanged(async (user) => {
      if (user === null) return;
      try {
        // この後処理は「ブロッキング」であることに注意。resが返ってくるまで時間がかかる
        // タイムアウトする可能性もある。
        const res = await axios.post(URL, {}, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${await user.getIdToken(false)}`,
          },
          responseType: 'blob',
        })
        if (res.status !== 200) {
          showSnackbar(`後処理ジョブの実行に失敗しました (status: ${res.status})`, "error");
        }
      } catch (e) {
        showSnackbar("後処理ジョブ実行時にエラーが発生しました", "error")
        console.error('実行中にエラーが発生しました:'+e)
      }
    })
  };

  return {
    handleClickPostProcess,
  }
}
