import axios from 'axios'

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL || "";

export default {
  get: async (path: string, token?: string) => {
    return await axios.get(path, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token !== undefined ? token : null}`,
      }
    })
  },
  post: (path: string, data: any) => {
    return axios.post(path, data)
  },
  baseURL: axios.defaults.baseURL
}
