import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Paper  from "@mui/material/Paper";
import { FC, ReactElement } from "react";

type Props = {
  children: ReactElement;
};

export const TableWrapper: FC<Props> = ({ children }) => {
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          "& .MuiTableCell-root": {
            border: "1px solid rgba(0, 0, 0, 1)",
          },
        }}
      >
        {children}
      </Table>
    </TableContainer>
  );
};
