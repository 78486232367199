import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { APIPathGetMenuByIdAndTargetDate} from "../../endpoint";
import { useFetch } from "../../hooks/useFetch";
import { menuDetail, MenuDetails, MenuPart } from "../../types/menuDetail";
import { assertIsDefined, assertIsDefinedOrNull } from "../../util/assertIsDefined";

export const useMenuDetail = () => {
  const { menuId, targetDay } = useParams();

  assertIsDefined(menuId);
  assertIsDefined(targetDay);

  const [menuDetails, setMenuDetails] = useState<MenuDetails>();

  const {data, hasError, isLoading, error} = useFetch<any>({
    url: APIPathGetMenuByIdAndTargetDate(menuId, targetDay),
  });
  assertIsDefinedOrNull(data);

  const targetDate = new Date(Number(targetDay.substring(0,4)),Number(targetDay.substring(5,7))-1,  Number(targetDay.substring(8,11)));
  useEffect(() => {
    try {
      if (data) {
        assertIsDefined(targetDate);
        fetchMenuDetails(data.meals[0], targetDate)
      }
    } catch (error) {
      console.log(error)
    }
  },[data]);  // eslint-disable-line

  function fetchMenuDetails(data: any, targetDate: Date){
    const tmpData: MenuDetails = {
      menuOfDayId: data["day"],
      targetDate: targetDate  ,
      breakfast: setMenuDetail("breakfast", data["meal"]["breakfast"]),
      lunch: setMenuDetail("lunch",data["meal"]["lunch"]),
      dinner: setMenuDetail("dinner",data["meal"]["dinner"]),
    }
    setMenuDetails(tmpData)
  }

  function setMenuDetail(menuType: any, data: any){
    let dishes :MenuPart[] = []
    for(let i=0; i< data["dishes"].length; i++){
      const tmpPartType: MenuPart ={
        code: data["dishes"][i]["code"],
        name: data["dishes"][i]["name"],
        cat_M: data["dishes"][i]["cat_M"],
        cat_S: data["dishes"][i]["cat_S"],
      }
      dishes.push(tmpPartType)
    }
    const menu: menuDetail = {
      menuDetaiId: "1",
      menuType: menuType as typeof menuType,
      price: Math.round(data["agg"]["price"]*10)/10,
      energy: Math.round(data["agg"]["energy"]*10)/10,
      protein: Math.round(data["agg"]["protein"]*10)/10,
      fat: Math.round(data["agg"]["fat"]*10)/10,
      carb: Math.round(data["agg"]["carb"]*10)/10,
      salt: Math.round(data["agg"]["salt"]*10)/10,
      potassium: Math.round(data["agg"]["potassium"]*10/10),
      phosphorus: Math.round(data["agg"]["phosphorus"]*10/10),
      menuImage: data["image_url"],
      isManual: data.is_manual,
      menuParts:dishes
    };

    return menu;
  }

  return {
    menuId,
    targetDate,
    menuDetails,
    hasError,
    error,
    isLoading,
  }
}
