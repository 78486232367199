import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ConditionFormValues } from "../../../../../types/condition";

type Props = {
  formName: string;
};

export const ConditionDisableField: FC<Props> = ({ formName }) => {
  const { control } = useFormContext<ConditionFormValues>();
  const name = `optionSpecificConditions.${formName}` as const;

  return (
    <FormControl
      sx={{
        width: "240px",
      }}
    >
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Checkbox
            {...field}
            checked={field.value}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
            onClick={(e) => {
              field.onChange(e);
            }}
        />
        )}
      />
    </FormControl>
  );
};
