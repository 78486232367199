import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";


import { MUIButton } from "../../atoms/Button/Button";
import ClearIcon from '@mui/icons-material/Clear';
import { pink } from '@mui/material/colors';
import { memo } from "react";
import { ManualMealDish } from "../../../types/menuManual";
import { prepareImage } from "../../../pages/manualDishes/useSelectDish.hooks";


type ManualContentProps = {
  manualDishes: ManualMealDish[],
  handleRemove: (uid: string) => void,
  handleSubmit: () => void,
}

export const ManualContent = memo((props: ManualContentProps) => {
  const { manualDishes, handleRemove, handleSubmit } = props;

  const listStyle = {
    border: "1px solid silver",
    minHeight: 80,
  };
  const noImagePath = "/images/NoImage1-m.png";

  return (
    <Grid> 
      <Grid>
        <List>
          {manualDishes.map((value: ManualMealDish, idx: number) => (
            <ListItem 
              key={idx} 
              secondaryAction={
                <IconButton edge="end" sx={{ marginRight: 3, }} onClick={() => handleRemove(value.source_uid)}>
                  <ClearIcon sx={{ color: pink[500] }} fontSize="large" />
                </IconButton>
              }
              disablePadding>
              <ListItemButton sx={listStyle} >
                {value.image === prepareImage ? (
                  <Box display={"flex"}  textAlign={'center'} alignItems="center" padding={1.5} height={100} border={"1px solid silver"}>
                    <Typography>献立生成前</Typography>
                  </Box>
                ): 
                (
                  <img src={value.image} alt={`${value.name}`} 
                    onError={e => {
                    e.currentTarget.src = noImagePath;
                    }}
                    width={100} height={100}
                  />
                )}
                <Box sx={{marginLeft: 6}} display="flex">
                  <ListItemText primary={value.source_uid} sx={{ minWidth: 100  }} />
                  <ListItemText primary={value.type}  sx={{ marginLeft: 7, minWidth: 10,  }} />
                  <ListItemText primary={value.name}  sx={{ marginLeft: 7, minWidth: 100,  }} />
                </Box>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Grid>
      
      <Grid marginTop={10}>
        <Box sx={{ display: "flex", justifyContent: "right" }}>
          <MUIButton
            onPush={handleSubmit}
            text={"保存する"}
          />
        </Box>
      </Grid>
    </Grid>
  )
}) 
