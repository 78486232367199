import Grid from "@mui/material/Grid";
import { lazy, Suspense } from "react";
import { useFormContext, UseFormReturn } from "react-hook-form";
import { CreateMenuType } from "../../../types/menuCreate";
import { ColumnTitle } from "../../atoms/columnTitle";
import { ControlDatePicker } from "../../atoms/ControlDatePicker/ControlDateDicker";
import { ControlTextField } from "../../atoms/ControlTextField/ControlTextField";
import { MUIButton } from "../../atoms/Button/Button";
import { LoadingSpinner } from "../../atoms/LoadingSpinner/LoadingSpinner";

type MenuCreateFormProps = {
  formMethods: UseFormReturn<CreateMenuType, any>,
  handleSubmit:  (values: CreateMenuType) => Promise<void>
}

export const MenuCreateForm = (props: MenuCreateFormProps) => {
  const { formMethods, handleSubmit } = props;
  const { control, trigger } = useFormContext<CreateMenuType>();
  
  const SuspenseComponent = lazy(() => import('./field/presetConditions'));
  
  return (
    <form>
      <Grid
        container
        sx={{
          marginY: (theme) => theme.spacing(4),
          rowGap: (theme) => theme.spacing(3),
        }}
      >
        <Grid item container direction="row" spacing={4}>
          <Grid container item sx={{ justifyContent: "space-between", }}>
            <Grid item>
              <ColumnTitle title={"対象月"} />
            </Grid>
            <Grid item>
              <ControlDatePicker 
                name="target_month" 
                control={control} 
                trigger={trigger} 
                format={'YYYY年MM月'}
                width={400} 
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={4}>
          <Grid container item sx={{ justifyContent: "space-between",}}>
            <Grid item>
              <ColumnTitle title={"献立名"} />
            </Grid>
            <Grid item>
              <ControlTextField type="text" name="name" control={control} trigger={trigger} width={400}/>
            </Grid>
          </Grid>
        </Grid>
        <Suspense fallback={<LoadingSpinner />}>
          <SuspenseComponent />
        </Suspense>
      </Grid>
      <Grid sx={{ display: "flex", justifyContent: "flex-end"}}>
        <MUIButton
          onPush={formMethods.handleSubmit(handleSubmit)}
          text={"作成"}
        />
      </Grid>
    </form>
  );
}


