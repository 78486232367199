import Grid from "@mui/material/Grid";
import { FC } from "react";
import { ColumnTitle } from "../../../../atoms/columnTitle";
import DayPicker from "../../../../atoms/dayPicker";

type Props = {
}

export const FixedDayCondition: FC<Props> = () => {
  return (
    <Grid
      container
      sx={{
        rowGap: (theme) => theme.spacing(4),
      }}
    >
      <ColumnTitle title={"納豆・洋食固定曜日指定"} />
      <Grid container
        sx={{
          border: "solid 1px #000",
          paddingX: (theme) => theme.spacing(16),
          paddingY: (theme) => theme.spacing(4),
          borderRadius: "8px",
        }}
      >
        <Grid container item sx={{ flexDirection: "row", justifyContent: "space-between", }}>
          <div>
            <h3>納豆固定曜日</h3>
            <DayPicker name="nattoKoteiYoubi" placeholder="納豆固定曜日" defaultValue="Mon" />
          </div>
          <div>
            <h3>洋食固定曜日1</h3>
            <DayPicker name="yoshokuKoteiYoubi1" placeholder="洋食固定曜日1" defaultValue="Sun"  />
          </div>
          <div>
            <h3>洋食固定曜日2</h3>
            <DayPicker name="yoshokuKoteiYoubi2" placeholder="洋食固定曜日2" defaultValue="Wed"  />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};
