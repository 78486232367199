import {FC, ChangeEvent, useState, useCallback} from "react";
import Grid from "@mui/material/Grid";
import { ColumnTitle } from "../../components/atoms/columnTitle";
import Typography from "@mui/material/Typography";
import { PageHeader } from "../../components/molecules/PageHeader/PageHeader";
import { Button, FormControl, InputLabel, Input } from '@mui/material';
import axios from 'axios';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import firebase from 'firebase/compat/app';
import {AuthProvider, useAuthContext} from "../../components/organisms/AuthProvider";

/**
 * /data_import に対応する機能群
 *
 * - 先月末データ（「ダウンロード」機能とファイル選択を介してのアップロード機能(CSV)）
 * - 商品データ（「変換済みダウンロード」機能とファイル選択を介してのアップロード機能(JSON)）
 * - 画像データ（「画像ダウンロード」機能とファイル選択を介してのアップロード機能(png画像を保存したフォルダのzip)
 *
 */
export const DataImport: FC = () => {
  const [selectedLastMonthFile, setSelectedLastMonthFile] = useState<File | null>(null);
  const [selectedDishMasterFile, setSelectedDishMasterFile] = useState<File | null>(null);
  const [selectedDishImageFile, setSelectedDishImageFile] = useState<File | null>(null);
  const token = useAuthContext().token ?? null;

  const handleLastMonthFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedLastMonthFile(event.target.files[0]);
    }
  };
  const handleDishMasterFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedDishMasterFile(event.target.files[0]);
    }
  };
  const handleDishImageFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedDishImageFile(event.target.files[0]);
    }
  };

  const handleLastMonthDownload = useCallback(async () => {
    try {
      if (token === null) return;

      const response = await axios.get(`/master/last_month_menu/download`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob', // レスポンスをBlobとして扱う
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'last_month_menu.csv'); // ファイル名と拡張子を設定
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);

    } catch (error) {
      console.error('ダウンロード中にエラーが発生しました:', error);
      alert('ファイルのダウンロード中にエラーが発生しました。');
    }
  }, [token]);

  const handleDishMasterDownload = useCallback(async () => {
    try {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (token === null) return;

        const response = await axios.get(`/master/dish/download`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: 'blob', // レスポンスをBlobとして扱う
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'df_preprocessed.csv'); // ファイル名と拡張子を設定
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      })

    } catch (error) {
      console.error('ダウンロード中にエラーが発生しました:', error);
      alert('ファイルのダウンロード中にエラーが発生しました。');
    }
  }, [token]);

  const handleDishImageDownload = useCallback(async () => {
    try {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (token === null) return;
        const response = await axios.get(`/master/dish-images/download`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: 'blob', // レスポンスをBlobとして扱う
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'images.zip'); // ファイル名と拡張子を設定
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      })

    } catch (error) {
      console.error('ダウンロード中にエラーが発生しました:', error);
      alert('ファイルのダウンロード中にエラーが発生しました。');
    }
  }, [token]);

  /**
   * 「先月末データ」のアップロードフォームに対応するハンドラ関数
   */
  const handleLastMonthSubmission = useCallback(async (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    if (token === null) return;

    if (!selectedLastMonthFile) {
      alert('ファイルが選択されていません。');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedLastMonthFile, selectedLastMonthFile.name);

    try {
      await axios.post(`/master/last_month_menu/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
      alert('ファイルが正常にアップロードされました。');

    } catch (error) {
      console.error('アップロード中にエラーが発生しました:', error);
      alert('ファイルのアップロード中にエラーが発生しました。');
    }
  }, [token, selectedLastMonthFile]);

  const handleDishMasterSubmission = useCallback(async (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    if (token === null) return;

    if (!selectedDishMasterFile) {
      alert('ファイルが選択されていません。');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedDishMasterFile, selectedDishMasterFile.name);

    try {
      await axios.post('/master/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
      alert('ファイルが正常にアップロードされました。');
    } catch (error) {
      console.error('アップロード中にエラーが発生しました:', error);
      alert('ファイルのアップロード中にエラーが発生しました。');
    }
  }, [token, selectedDishMasterFile]);

  const handleDishImageSubmission = useCallback(async (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    if (token === null) return;

    if (!selectedDishImageFile) {
      alert('ファイルが選択されていません。');
      return;
    }

    // FormDataを使用してファイルを送信する準備
    const formData = new FormData();
    formData.append('file', selectedDishImageFile, selectedDishImageFile.name);

    // APIリクエストを行う
    try {
      // ここでファイルを送信するためのAPIを呼び出す
      // 実際のURLに置き換えてください
      await axios.post('master/dish-images/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
      alert('ファイルが正常にアップロードされました。');
    } catch (error) {
      console.error('アップロード中にエラーが発生しました:', error);
      alert('ファイルのアップロード中にエラーが発生しました。');
    }
  }, [token, selectedDishImageFile]);

  return (
    <>
      <PageHeader title={"データインポート"} />
      <Grid
        container
        sx={{
          rowGap: (theme) => theme.spacing(4),
        }}
      >
        <Grid container
          sx={{
            border: "solid 1px #000",
            paddingX: (theme) => theme.spacing(16),
            paddingY: (theme) => theme.spacing(4),
            borderRadius: "8px",
          }}
        >
          <ColumnTitle title={"先月末データ"} />
          <Grid container item sx={{ flexDirection: "row", justifyContent: "space-between", }}>
            <Typography
              sx={{
                fontSize: "20px",
                fontStyle: "normal",
                letterSpacing: "0.5px",
                lineHeight: "35px",
              }}
            >
              ダウンロードしたファイルと同じ形式のファイルをアップロードください
            </Typography>
          </Grid>
          <Grid container item sx={{ flexDirection: "row", justifyContent: "space-between", }}>
            <form onSubmit={handleLastMonthSubmission}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <Input
                  id="file-input"
                  type="file"
                  onChange={handleLastMonthFileChange}
                  inputProps={{ 'aria-label': 'ファイルアップロード' }}
                />
              </FormControl>
              <Button variant="contained" color="primary" type="submit">
                先月末データアップロード
              </Button>
              <Button variant="contained" color="secondary" onClick={handleLastMonthDownload} startIcon={<FileDownloadIcon />}
                      sx={{ marginLeft: "8px" }}>
                ダウンロード
              </Button>
            </form>
          </Grid>
        </Grid>

        <Grid container
          sx={{
            border: "solid 1px #000",
            paddingX: (theme) => theme.spacing(16),
            paddingY: (theme) => theme.spacing(4),
            borderRadius: "8px",
          }}
        >
          <ColumnTitle title={"商品データ"} />
          <Grid container item sx={{ flexDirection: "row", justifyContent: "space-between", }}>
            <Typography
              sx={{
                fontSize: "20px",
                fontStyle: "normal",
                letterSpacing: "0.5px",
                lineHeight: "35px",
              }}
            >
              メニュー作成時にJDSCへご提供いただく.csv形式のファイルをアップロードください
            </Typography>
          </Grid>
          <Grid container item sx={{ flexDirection: "row", justifyContent: "space-between", }}>
            <form onSubmit={handleDishMasterSubmission}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <Input
                  id="file-input"
                  type="file"
                  onChange={handleDishMasterFileChange}
                  inputProps={{ 'aria-label': 'ファイルアップロード' }}
                />
              </FormControl>
              <Button variant="contained" color="primary" type="submit">
                商品データアップロード
              </Button>
              <Button variant="contained" color="secondary" onClick={handleDishMasterDownload} startIcon={<FileDownloadIcon />}
                      sx={{ marginLeft: "8px" }}>
                変換済みダウンロード
              </Button>
            </form>
          </Grid>
        </Grid>

        <Grid container
          sx={{
            border: "solid 1px #000",
            paddingX: (theme) => theme.spacing(16),
            paddingY: (theme) => theme.spacing(4),
            borderRadius: "8px",
          }}
        >
          <ColumnTitle title={"画像データ"} />
          <Grid container item sx={{ flexDirection: "row", justifyContent: "space-between", }}>
            <Typography
              sx={{
                fontSize: "20px",
                fontStyle: "normal",
                letterSpacing: "0.5px",
                lineHeight: "35px",
              }}
            >
              切り抜き済み画像（png）をzipに圧縮してアップロードください。<br/>
              - 一度にアップロードできるファイルサイズの目安は32MBです。<br/>
              - 画像ファイルの名前はそれぞれ "(商品コード).png" もしくは "(主菜商品コード)_(添物商品コード).png" としてください<br/>
            </Typography>
          </Grid>
          <Grid container item sx={{ flexDirection: "row", justifyContent: "space-between", }}>
            <form onSubmit={handleDishImageSubmission}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <Input
                  id="file-input"
                  type="file"
                  onChange={handleDishImageFileChange}
                  inputProps={{ 'aria-label': '画像アップロード' }}
                />
              </FormControl>
              <Button variant="contained" color="primary" type="submit">
                画像アップロード
              </Button>
              <Button variant="contained" color="secondary" onClick={handleDishImageDownload} startIcon={<FileDownloadIcon />}
                      sx={{ marginLeft: "8px" }}>
                画像ダウンロード
              </Button>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DataImport;
