import { FC, memo } from "react";
import { PageTitle } from "../../components/atoms/pageTitle";
import { MembershipTypeTable } from "../../components/organisms/MembershipType/MembershipTypeTable";

export const MembershipTypePage: FC = memo(() => {
  return (
    <>
      <PageTitle title={"会員種別一覧"} />
      <MembershipTypeTable />
    </>
  );
});
