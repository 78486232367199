import { ChangeEvent, DragEvent, useRef, useState } from "react";
import { usePost } from "../../hooks/usePost";
import { APIPathPostDishMaster } from "../../endpoint";
import { useSnackbar } from "../../components/organisms/SnackProvider";

export const usePostData = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { showSnackbar } = useSnackbar()

  const onDrop = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault();

    const file = e.dataTransfer.files[0];

    if (file && file.name.endsWith(".csv") && isValidFileName(file.name)) {
      setSelectedFile(file);
      postFile(file);
    } else {
      showSnackbar(`ファイル名に問題がありました。「YYYYMMファイル名」のようなフォーマットで入力してください。(ex)202203献立商品)`, 'error');
    }
  }
  const onDragOver = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
  }

  const handleButtonClick = () => {
    inputRef.current?.click();
  }
  const onFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const files = event.target.files;

    if (files && files.length > 0 && files[0].name.endsWith('.csv') && isValidFileName(files[0].name)) {
      setSelectedFile(files[0]);
      postFile(files[0]);
      event.target.value = ''; 
    } else {
      showSnackbar(`ファイル名に問題がありました。「YYYYMMファイル名」のようなフォーマットで入力してください。(ex)202203献立商品)`, 'error');
    }
  };

  const [ doPost, isLoading ] = usePost({
    method: 'post',
    url: APIPathPostDishMaster,
  })

  const isValidFileName = (filename: string): boolean => {
    const pattern = /^\d{4}\d{2}.*\.csv$/;
    return pattern.test(filename);
  }

  const postFile = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);

    doPost({
      params: formData,
      onSuccess: () => {
          showSnackbar(`データの保存が完了しました。`, 'success');
      },
      onError: (err) => {
          showSnackbar(`データの保存に失敗しました。`, "error");
      }
    })
  }

  return {
    selectedFile,
    isLoading,
    inputRef,
    onDrop,
    onDragOver,
    handleButtonClick,
    onFileChange,
  }

}