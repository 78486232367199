import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import { FC } from "react";
import { LinkButton } from "../../../../atoms/linkButton";
import { mealTypeTitle } from "../../../../../types/menu";
import { Menu } from "../../../../../dataModel/menu/Menu";
import { yellow } from '@mui/material/colors';
import { Grid } from "@mui/material";


type Props = {
  menu: Menu,
  targetDay: string; // YYYY-MM-DD
  menuId: any;
};

export const MenuCard: FC<Props> = ({ menu, targetDay, menuId }) => {
  const hasManualMeal = (menu.is_manual && menu.manual_meal_id !== "");
  const editURL = `/menu/${menuId}/manual_create/${targetDay}/${menu.menuType}?${hasManualMeal ? `id=${menu.manual_meal_id}` : ''}`
  const labelWidth = "80px";
  const imageHeight = "150px";

  return (
    <Card
      sx={{
        marginY: (theme) => theme.spacing(1),
        boxShadow: 3,
        backgroundColor: hasManualMeal ? yellow[200] : "",
      }}
    >
      {hasManualMeal ? (
        <Box display={"flex"} alignItems="center" justifyContent={'center'} height={imageHeight} border={"1px solid silver"}>
          <Typography variant="h5">献立生成前</Typography>
        </Box>
      ) : (
        <CardMedia
          component="img"
          image={menu.image_url !== "" ? menu.image_url : `${process.env.PUBLIC_URL}/dish_sample.png`}
          sx={{ height: imageHeight }}
        />
      )}
      <CardContent>
        <Typography sx={{ fontWeight: "bold", textAlign: "center", fontSize: "0.8em" }}>
          {mealTypeTitle[menu.menuType]}
        </Typography>
        <Grid container justifyContent="center" alignItems="center">
          <Grid display={"block"}>
            <Grid display={"flex"}>
              <Typography sx={{ width: labelWidth, fontSize: "0.8rem" }}>価格</Typography>
              <Typography sx={{ fontSize: "0.8rem" }}>{Math.round(menu.menuInfo.price * 10) / 10} 円</Typography>
            </Grid>
            <Grid display={"flex"}>
              <Typography sx={{ width: labelWidth, fontSize: "0.8rem" }}>エネルギー</Typography>
              <Typography sx={{ fontSize: "0.8rem" }}>{Math.round(menu.menuInfo.energy * 10) / 10} kcal</Typography>
            </Grid>
            <Grid display={"flex"}>
              <Typography sx={{ width: labelWidth, fontSize: "0.8rem" }}>たんぱく質</Typography>
              <Typography sx={{ fontSize: "0.8rem" }}>{Math.round(menu.menuInfo.protein * 10) / 10} g</Typography>
            </Grid>
            <Grid display={"flex"}>
              <Typography sx={{ width: labelWidth, fontSize: "0.8rem" }}>脂質</Typography>
              <Typography sx={{ fontSize: "0.8rem" }}>{Math.round(menu.menuInfo.fat * 10) / 10} g</Typography>
            </Grid>
            <Grid display={"flex"}>
              <Typography sx={{ width: labelWidth, fontSize: "0.8rem" }}>炭水化物</Typography>
              <Typography sx={{ fontSize: "0.8rem" }}>{Math.round(menu.menuInfo.carb * 10) / 10} g</Typography>
            </Grid>
            <Grid display={"flex"}>
              <Typography sx={{ width: labelWidth, fontSize: "0.8rem" }}>食塩相当量</Typography>
              <Typography sx={{ fontSize: "0.8rem" }}>{Math.round(menu.menuInfo.salt * 10) / 10} g</Typography>
            </Grid>
            <Grid display={"flex"}>
              <Typography sx={{ width: labelWidth, fontSize: "0.8rem" }}>カリウム</Typography>
              <Typography sx={{ fontSize: "0.8rem" }}>{Math.round(menu.menuInfo.potassium * 10) / 10} mg</Typography>
            </Grid>
            <Grid display={"flex"}>
              <Typography sx={{ width: labelWidth, fontSize: "0.8rem" }}>リン</Typography>
              <Typography sx={{ fontSize: "0.8rem" }}>{Math.round(menu.menuInfo.phosphorus * 10) / 10} mg</Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions
        sx={{
          justifyContent: "center",
          fontSize: "0.8em",
        }}
      >
        <LinkButton url={editURL} text={hasManualMeal ? "編集" : "献立指定"} />
      </CardActions>
    </Card>
  );
};
