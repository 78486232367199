import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { FC } from "react";

type Props = {
  onPush: () => void;
  text: string;
};

export const DeleteButton: FC<Props> = ({ onPush, text }) => {
  return (
    <Button
      variant="outlined"
      onClick={() => onPush()}
      sx={{
        backgroundColor: (theme) => theme.palette.error.main,
        border: "solid black 1px",
      }}
    >
      <Typography sx={{ color: (theme) => theme.palette.error.contrastText }}>{text}</Typography>
    </Button>
  );
};
