import { useCallback, useEffect, useMemo, useState } from 'react'
import axios, { AxiosError, AxiosResponse, Method } from 'axios'
import firebase from 'firebase/compat/app'
interface Params {
  [key: string]: any
}

interface Args<T> {
  url?: string
  params?: Params
  body?: Params
  method?: Method
  onSuccess?: (data?: T) => void
  onError?: (err: AxiosError) => void
}

interface PostRequest {
  method?: Method
  url?: string
  params?: Params
  body?: Params
  headers?: Headers
}

type DoPost<T> = ({ url, params }: Args<T>) => Promise<void>

type PostResponse<T> = [
  doPost: DoPost<T>,
  isLoading: boolean
]

export function usePost<T> ({
  method = 'post',
  url,
  params,
}: PostRequest): PostResponse<T> {
  const methodInternal = useMemo(() => method, [method])
  const urlInternal = useMemo(() => url, [url])
  const paramsInternal = useMemo(() => params, [params])
  const [isLoading, setLoading] = useState(false)

  const doPost = useCallback<DoPost<T>>(
    async <T>(args: Args<T>) => {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user === null) return;
        const reqUrl = args.url ?? urlInternal ?? ''
        if (!reqUrl || !methodInternal) return;

        try {
          setLoading(true)
          const res: AxiosResponse<T> = await axios.request({
            method: args?.method ?? methodInternal,
            url: reqUrl,
            data: args?.params ?? paramsInternal ?? undefined,
            headers: {
              Authorization: `Bearer ${await user.getIdToken(false)?? null}`,
            }
          })
          args.onSuccess?.(res.data)
        } catch (err) {
          args.onError?.(err as AxiosError)
        } finally {
          setLoading(false)
        }
      })
    },
    [methodInternal, paramsInternal, urlInternal]
  )

  const clear = useCallback(() => {
    setLoading(false)
  }, [])

  useEffect(() => () => clear(), [clear])

  return [
    doPost,
    isLoading
  ]
}