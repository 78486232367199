import {useCallback, useEffect, useState} from 'react'
import axios, { AxiosError } from 'axios'
import firebase from "firebase/compat/app";

// TODO: putに対応して usePostをこちらに載せ替える
interface FetchRequest {
  method?: 'get' | 'post'
  url: string
  params?: Params
  headers?: Headers
}

interface FetchResponse<T> {
  data?: T | null
  error: any
  hasError: boolean
  isLoading: boolean
}

interface Headers {
  [key: string]: any
}

interface Params {
  [key: string]: any
}

/**
 * Firebase AuthenticationのBearerトークンをヘッダに設定しつつurlにたいしてGETもしくはPOSTリクエストを送信する。
 * トークンの準備が出来るまでクエリは行われない
 * 認証されていないケースのほか、すでに認証された後でも最初期のレンダリングのタイミングで一時的にそのようになる。
 */
export function useFetch<T> ({
  url,
  method = 'get',
  params,
  headers,
}: FetchRequest): FetchResponse<T> {
  const [data, setData] = useState<T | null>(null)
  const [isLoading, setLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  // 以前 AxiosError だけだったが認証されてないケースを返せないのでanyにしちゃった(*ﾉω・*)ﾃﾍ
  const [error, setError] = useState<any>(null)
  const [token, setToken] = useState<string | null>(null);
  useEffect( () => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user === null) return;
      setToken(await user.getIdToken());
    });
  })
  let ax = useCallback(() => {
    axios.request({
      url: url,
      method: method,
      params: params,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      setData(res.data);
    })
  }, [url, params, token, method])

  useEffect( () => {
    if (token === null) return;

    try {
      ax();
    } catch (err) {
      setHasError(true);
      setError(err as AxiosError);
    } finally {
      setLoading(false);
    }
  }, [url, method, params, headers, token, ax]);
  return {
    data,
    error,
    hasError,
    isLoading
  }
}
