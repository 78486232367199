import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Box from "@mui/material/Box";
import { FC, memo } from "react";
import { DishesTableColumn } from "../../../../const/tableColumns";
import { DishListType } from "../../../../types/dish";
import { TableHeader } from "../../../atoms/tableHeader";
import { TableWrapper } from "../../../atoms/tableWrapper";
import { TablePaginator } from "../../TablePaginator";
import { DishTableActionCell } from "./dishTableActionCell";

type DishesTableProps = {
  dishData:  DishListType[],
  page: number,
  fetchNum: number,
  totalCount: number,
  handleChangePage: (event: unknown, newPage: number) => Promise<void>,
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => Promise<void>,
}
export const DishesTable: FC<DishesTableProps> = memo(({
  dishData,
  page ,
  fetchNum,
  totalCount,
  handleChangePage,
  handleChangeRowsPerPage
}: DishesTableProps) => {
  return (
    <>
      <TableWrapper>
        <>
          <TableHeader headCells={DishesTableColumn} />

          <TableBody>
            {dishData.map((data:  DishListType, idx: number) => (
              <TableRow key={data.name + idx}>
                <TableCell
                  variant="head"
                  padding={"normal"}
                  sx={{
                    backgroundColor: (theme) => theme.palette.secondary.main,
                    color: (theme) => theme.palette.secondary.contrastText,
                    textAlign: "center",
                  }}
                >
                  {data.id}
                </TableCell>
                <TableCell
                  variant="head"
                  padding={"normal"}
                  sx={{
                    backgroundColor: (theme) => theme.palette.secondary.main,
                    color: (theme) => theme.palette.secondary.contrastText,
                    textAlign: "center",
                  }}
                >
                  {data.name}
                </TableCell>
                <TableCell
                  variant="head"
                  padding={"normal"}
                  sx={{
                    backgroundColor: (theme) => theme.palette.secondary.main,
                    color: (theme) => theme.palette.secondary.contrastText,
                    textAlign: "center",
                  }}
                >
                  {data.cat_M}
                </TableCell>
                <TableCell
                  variant="head"
                  padding={"normal"}
                  sx={{
                    backgroundColor: (theme) => theme.palette.secondary.main,
                    color: (theme) => theme.palette.secondary.contrastText,
                    textAlign: "center",
                  }}
                >
                  {data.wayotyuCategory}
                </TableCell>

                {/* アクションボタン */}
                <DishTableActionCell url={`/dishes/${data.id}`} />
              </TableRow>
            ))}
          </TableBody>
        </>
      </TableWrapper>
      <Box sx={{ border: '2px solid', display: "flex", borderColor: 'grey.500', width: '100%', justifyContent: 'center'}} display="flex" justifyContent="flex-end">
        <TablePaginator
          page={page}
          rowsPerPage={fetchNum}
          count={totalCount}
          handleChangePage={handleChangePage}
          handleRowPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </>
  );
});

