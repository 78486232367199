import { Grid } from "@mui/material";
import { FC } from "react";
import { BackButton } from "../../atoms/BackButton/BackButton";
import { PageTitle } from "../../atoms/pageTitle";

type PageHeaderProps = {
  title: string;
};

export const PageHeader: FC<PageHeaderProps> = ({
  title,
}) => (
  <Grid sx={{ display: "flex", alignItems: "center" }}>
    <BackButton />
    <PageTitle title={title} />
  </Grid>
)