import TablePagination from "@mui/material/TablePagination";
import { FC } from "react";
import { TablePaginationActions } from './tablePaginationAction';

type Props = {
  page: number;
  rowsPerPage: number;
  count?: number;
  handleChangePage: (event: unknown, newPage: number) => Promise<void>;
  handleRowPerPageChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
};

export const TablePaginator: FC<Props> = ({page, rowsPerPage, count = 900, handleChangePage, handleRowPerPageChange }) => {

  return (
    <TablePagination
      component="div"
      // TODO: あとで商品の件数を取得できるようにする
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleRowPerPageChange}
      ActionsComponent={TablePaginationActions}
    />
  );
};
