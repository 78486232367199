import { FC, memo } from "react";
import { ConditionForm } from "../../components/organisms/ConditionForm";
import { usePresetConditionsForm } from "./usePresetConditionForm.hook";
import { useLastMonthMenuList } from "./useLastMonthMenu.hooks";
import { PageHeader } from "../../components/molecules/PageHeader/PageHeader";
import {LoadingTemplate} from "../../components/template/LoadingTemplate/LoadingTemplate";
import {useSnackbar} from "../../components/organisms/SnackProvider";

export const CreatePresetConditionsPage: FC = memo(() => {
  const { showSnackbar } = useSnackbar();
  const { formMethods, handleCreate } = usePresetConditionsForm();
  const { isLoading, error, menuVariationList, } = useLastMonthMenuList();

  if (error) {
    showSnackbar(`エラーが発生しました。: ${error}`, 'error')
  }

  return (
    <>
      <PageHeader title={"新規プリセット条件作成"} />
      { isLoading ? <LoadingTemplate/> :
        <ConditionForm
          formMethods={formMethods}
          onSubmit={() => handleCreate()}
          isPresetConditions={true}
          lastMonthMenu={menuVariationList} />
      }
    </>
  );
});
