import Grid from "@mui/material/Grid";
import { FC, memo } from "react";
import { MenuPartsComponent } from "../../components/organisms/MenuDetail/menuPartsComponent";
import { useSnackbar } from "../../components/organisms/SnackProvider";
import { LoadingTemplate } from "../../components/template/LoadingTemplate/LoadingTemplate";
import { useMenuDetail } from "./useMenuDetail.hooks";
import { PageHeader } from "../../components/molecules/PageHeader/PageHeader";


// 取得するデータ：meals_of_day_tableに紐づいたもろもろ
export const MenuDetailPage: FC = memo(() => {
  const {targetDate, menuDetails, isLoading, hasError, error, } = useMenuDetail();

  const { showSnackbar } = useSnackbar();
  if (hasError) {
    showSnackbar(`取得時にエラーが発生しました。: ${error}`, 'error')
  }

  if (isLoading) {
    return <LoadingTemplate />
  }

  return (
    <>
      <Grid marginLeft={2}>
        <PageHeader title={`献立詳細 : ${targetDate.getFullYear()}年 ${targetDate.getMonth() + 1}月 ${targetDate.getDate()} 日`} />
      </Grid>

      <Grid
        container
        sx={{
          paddingTop: (theme) => theme.spacing(5),
          paddingX: (theme) => theme.spacing(3),
          rowGap: (theme) => theme.spacing(4),
        }}
      >
        { menuDetails && <MenuPartsComponent title={"朝食"} data={menuDetails.breakfast} /> }
        { menuDetails && <MenuPartsComponent title={"昼食"} data={menuDetails.lunch} /> }
        { menuDetails && <MenuPartsComponent title={"夕食"} data={menuDetails.dinner} /> }
      </Grid>
    </>
  );
});
