import Button from "@mui/material/Button";
import { FC } from "react";

type ButtonProps = {
  onPush: (() => void) |  ((e: React.MouseEvent<any>) => any),
  text: string,
  color?: "primary" | "secondary" | "success" | "error",
  disabled?: boolean,
};

// primaryのcolorが上書きされているので使うときは注意する
export const MUIButton: FC<ButtonProps> = ({
  onPush, 
  text, 
  color = "success", 
  disabled=false 
}) => {
  return (
    <Button
      variant="contained"
      color={color}
      onClick={onPush}
      disabled={disabled}
      data-testid={'test'}
    >
      {text}
    </Button>
  );
};
