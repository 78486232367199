import React, {useCallback, useState} from "react";
import api from "../../const/api";
import { APIGetDishesByVal } from "../../endpoint";
import { Dish } from "../../types/dish";
import { ManualMealDish } from "../../types/menuManual";
import {useAuthContext} from "../../components/organisms/AuthProvider";

// domain 少し帯びているいるのでここに置いている
export type Option = {
  readonly value: string;
  readonly label: string;
  readonly type: string;
}

export const prepareImage = "prepare-image";

export const useSelectDish = ( addList: (val: ManualMealDish) => void,) => {
  const [options, setOptions] = useState<Option[]>([]);
  const [beforeVal, setBeforeVal] = useState<string>("");  
  const [selectedVal, setSelectedVal] = useState<Option>();
  const [isSearching, setIsSearching] = useState(false);
  const token = useAuthContext().token;

  const handleSearch = useCallback(async (inputVal: string) => {
    setBeforeVal(inputVal);
    if (inputVal.length < 2) return;
    if (token === null || token === undefined) return;

    const searchData = {
      val: inputVal
    };
    const params = new URLSearchParams(searchData).toString();

    // TODO: useFetch使えるなら変える
    if (inputVal.length > 0 && beforeVal !== inputVal) {
      try {
        setIsSearching(true);

        const result = await api.get(APIGetDishesByVal(params), token);
        const dishDetail: Dish[] = result.data;
        let target: Option[] = [];
        for (let i = 0; i < dishDetail.length; i++) {
          target.push({
            label: dishDetail[i].name, 
            value: dishDetail[i].source_uid,
            type:  dishDetail[i].type,
          })
        }
        setOptions(target);
      } catch (e) {
        console.error(e);
      } finally{
        setIsSearching(false);
      }
    } else {
      setOptions([]); 
    }
  }, [beforeVal, token]);

  const handleSelect = (e: React.SyntheticEvent<Element, Event>, val: Option) => {
    if (val === null) return;

    setSelectedVal(val);
    const listItem: ManualMealDish = {
      name: val?.label ?? "",
      source_uid: val?.value ?? "",
      type: val.type ?? '',
      image: prepareImage,
    }
    addList(listItem);

    setOptions([]); 
  };

  return {
    options,
    isSearching,
    selectedVal,
    handleSearch,
    handleSelect,
  };
};
