import Grid from "@mui/material/Grid";
import { FC } from "react";
import { ColumnTitle } from "../../../../atoms/columnTitle";
import { useFormContext } from "react-hook-form";
import { ConditionFormValues } from "../../../../../types/condition";
import { Box, Typography } from "@mui/material";
import { ControlSelect } from "../../../../atoms/ControlSelect/ControlSelect";
import { MenuListType } from "../../../../../types/menus";

type Props = {
  lastMonthMenu: MenuListType[],
}

export const LastMonthMenuCondition: FC<Props> = (lastMonthMenu) => {
  const {control, trigger} = useFormContext();
  return (
    <Grid
      container
      sx={{
        rowGap: (theme) => theme.spacing(4),
      }}
    >
      <ColumnTitle title={"先月末献立指定"} />
      <Grid container item sx={{ flexDirection: "row", justifyContent: "space-between", }}>
        <ControlSelect
          name="lastMonthMenuJobId"
          options={lastMonthMenu.lastMonthMenu}
          control={control}
          label="先月末献立"
          defaultValue={""}
        />
      </Grid>
    </Grid>
  );
};
