import createTheme from "@mui/material/styles/createTheme";

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: { main: "#E86D01", contrastText: "#FFFFFF" },
    secondary: { main: "#FFFFFFF", contrastText: "#000000" },
    success: { main: "#27AE60", contrastText: "#FFFFFF" },
    background: { default: "#FFFFFF" },
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: { main: "#4764b8", contrastText: "#ffffff" },
    secondary: { main: "#f02545", contrastText: "#ffffff" },
    success: { main: "#47b31f" },
    background: { default: "#f7f7f8" },
  },
});

//   テーマによって選ぶようにする
export const appTheme = false ? darkTheme : lightTheme;
