import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../const/api";
import { SearchDish } from "../../types/dish";
import { useSnackbar } from "../../components/organisms/SnackProvider";
import { DishListType } from "../../types/dish";
import { APIGetDishes } from "../../endpoint";
import { yupResolver } from "@hookform/resolvers/yup";
import { DishSchema } from "../../lib/Validation/yup/dishSchema";
import {useAuthContext} from "../../components/organisms/AuthProvider";

export const useDishes = () => {
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as { page?: number };

  const [loading, setLoading] = useState(true);
  const [dishData, setDishData] = useState([] as DishListType[]);
  const [page, setPage] = useState(state?.page ?? 0);
  const [totalCount, setTotalCount] = useState(0);
  const [fetchNum, setFetchNum] = useState(10);
  const token = useAuthContext().token ?? null;

  // GET 検索Form
  const initialValues: SearchDish  = {
    source_id: "",
    name: "",
    category: "",
    type: "",
  }

  const formMethods = useForm<SearchDish>({
    mode: "onChange",
    defaultValues: initialValues,
    resolver: yupResolver(DishSchema),
  });

  // 検索ハンドラを作成する
  const handleSearch = async () => {
    if (token === null) return;

    const values = formMethods.getValues();
    const searchData = {
      source_id: values.source_id,
      name: values.name,
      category: values.category,
      type: String(values.type)
    }
    const query = new URLSearchParams(searchData).toString();
    try {
      setLoading(true);
      const result = await api.get(APIGetDishes(page, fetchNum, query), token);
      updateDishData(result.data.records);
      setTotalCount(result.data.total_count)
    } catch (error) {
      showSnackbar("データ取得時にエラーが発生しました。", "error");
      console.log(error);
    } finally {
      setLoading(false)
    }
  };

  // 初期データの取得
  useEffect(() => {
    if (token === null) return;
    try {
      const values = formMethods.getValues();
      const searchData = {
        source_id: values.source_id,
        name: values.name,
        category: values.category,
        type: String(values.type)
      }
      const query = new URLSearchParams(searchData).toString();
      api.get(APIGetDishes(page, fetchNum, query), token).then(
        (result) => {
          updateDishData(result.data.records);
          setTotalCount(result.data.total_count)
        }
      )
    } catch (error) {
      showSnackbar("データ取得時にエラーが発生しました。", "error");
      console.log(error)
    } finally {
      setLoading(false);
    }
  }, [page, fetchNum, token]); // eslint-disable-line

  const handleChangePage = useCallback(async (event: unknown, newPage: number) => {
    setPage(newPage);
    const result = await api.get(APIGetDishes(page, fetchNum));
    navigate("/dishes", { state: { page: newPage } });
    updateDishData(result.data);
  }, [page, setPage, updateDishData]); // eslint-disable-line

  const handleChangeRowsPerPage = useCallback(async (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFetchNum(+event.target.value);
    const result = await api.get(APIGetDishes(page, fetchNum));
    updateDishData(result.data);
  }, [setFetchNum, updateDishData]); // eslint-disable-line

  function updateDishData(data: any[]) {
    let tmpList: DishListType[] =[]
    data.map((item: any) => {
      const dish: DishListType = {
        id: item['source_uid'],
        name: item['name'],
        cat_M: item['type'],
        wayotyuCategory: item['category'],
      };
      tmpList.push(dish);
    });
    setDishData(tmpList);
  }

  return {
    dishData,
    page,
    totalCount,
    fetchNum,
    loading,
    handleChangePage,
    handleChangeRowsPerPage,
    formMethods,
    handleSearch,
  }
}
