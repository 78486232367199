import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { APIPostCreateMenu } from "../../endpoint";
import { usePost } from "../../hooks/usePost";
import { CreateMenuType } from "../../types/menuCreate";
import { useSnackbar } from "../../components/organisms/SnackProvider";
import { MenuSchema } from "../../lib/Validation/yup/menu";

export const useCreateMenu = () => {
  const { membershipTypeId, targetMonth } = useParams();

  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();


  const initialValue: CreateMenuType = {
    target_month: targetMonth ? new Date(targetMonth) : new Date(),
    name: "",
    preset_conditions_id: "",
    membership_type_id: membershipTypeId ?? '',
  } 

  const formMethods = useForm<CreateMenuType>({
    mode: "onChange",
    defaultValues: initialValue,
    resolver: yupResolver(MenuSchema)
  });

  const [ doPost, isLoading ] = usePost({
    method: 'post',
    url: APIPostCreateMenu(membershipTypeId),
  });
  
  const handleSubmit = async (values: CreateMenuType) => {
    doPost({
      params: values,
      onSuccess: () => {
          navigate(`/membership_type/${membershipTypeId}/menu`);
          showSnackbar("プリセット制約条件を更新しました", "success");
      },
      onError: (err) => {
          showSnackbar(`更新に失敗しました: ${err.message}`, "error");
      }
    });
  };

  return {
    formMethods,
    isLoading,
    handleSubmit,
  }
}