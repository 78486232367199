import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import { FC } from "react";

type Props = {
  title: string;
  description?: string
  onDrop: (e: React.DragEvent<HTMLDivElement>) => void;
  onDragOver: (e: React.DragEvent<HTMLDivElement>) => void;
  onClick: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inputRef: React.RefObject<HTMLInputElement>;
  accept: string;
  multiple: boolean;
};

export const FileUploadCard: FC<Props> = ({
  title,
  onDrop,
  onDragOver,
  onClick,
  onChange,
  inputRef,
  accept,
  multiple,
  description
}) => {
  return (
    <Card
      sx={{
        minWidth: 500,
        padding: 4,
        border: "1px dashed #ccc",
        transition: "0.3s",
        "&:hover": {
          boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
          backgroundColor: "#e1e1e1",
        },
      }}
      onDrop={onDrop}
      onDragOver={onDragOver}
    >
      <CardHeader title={title} />
      <CardContent>
        <Typography variant="h5" component="div">
          ファイルをドラック＆ドロップしてアップロードする
        </Typography>
      </CardContent>

      <CardActions>
        <Typography variant="h6" color="text.secondary">
          もしくは
          <Button sx={{ color: "#0000FF" }} onClick={onClick}>
          フォルダから選択してアップロードする
        </Button>
        </Typography>
        <input
          ref={inputRef}
          type="file"
          hidden
          onChange={onChange}
          accept={accept}
          multiple={multiple}
        />
      </CardActions>
      <CardActions>
        <Typography variant="body2" color="text.secondary" fontSize={15}>
          {description}
        </Typography>
      </CardActions>
    </Card>
  );
};
