import Grid from "@mui/material/Grid";
import { FC, memo, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MUIButton } from "../../components/atoms/Button/Button";
import { UpdateButton } from "../../components/atoms/updateButton";
import { MenuView } from "../../components/organisms/Menu/menuView";
import { useSnackbar } from "../../components/organisms/SnackProvider";
import { useMenu } from "./useMenu.hooks";
import { LoadingTemplate } from "../../components/template/LoadingTemplate/LoadingTemplate";
import { Input } from "../../components/molecules/Input";
import { useModal } from "../../components/molecules/Modal/useModal";
import { ModalMUI } from "../../components/molecules/Modal/Modal";
import { MenuEvaluation } from "../../components/organisms/Menu/MenuEvaluation/MenuEvaluation";
import { Alert, Box, CircularProgress } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { MenuJobStatusStr } from "../../types/menu";
import { useDownloadArtifact } from "./useDownloadArtifact";
import { useExecPostProcess } from "./useExecPostProcess";
import { assertIsDefined } from "../../util/assertIsDefined";
import { format } from "../../util/date";
import { PageHeader } from "../../components/molecules/PageHeader/PageHeader";

export const MenuPage: FC = memo(() => {
  const { menuId } = useParams();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  assertIsDefined(menuId);

  const {menu, insufficient, fetchLoading, disabledSubmit, menuForMonth, isJobNotFound, handleClickGenerateMenu} = useMenu(menuId, showSnackbar);
  const {open, handleOpen, handleClose} = useModal();
  const {isLoading: isDownloading, isSuccess: isDownloadSuccess, handleClickDownload} = useDownloadArtifact();
  const {handleClickPostProcess} = useExecPostProcess();

  const dateStr = useMemo(() => menuForMonth ? format(menuForMonth.targetMonth, "YYYY-MM") : null ,[menuForMonth])

  if (fetchLoading) {
    return (
      <LoadingTemplate />
    )
  }

  // 小数点以下3桁
  function calPriceAvg(val: any): number {
    return Math.round(val*1000)/1000
  }

  return (
    <>
      <Grid sx={{display: "flex",}}>
        {menu && (
          <PageHeader title={`献立名 : ${menu.name}`} />
        )}
        <div style={{ flexGrow: 1 }} />
        <Grid sx={{ display: "flex", columnGap: (theme) => theme.spacing(4), }}>
          { menu && (
            <UpdateButton
              text={"献立評価"}
              onPush={() => {
                handleOpen();
              }}
              disabled={menu && menu.latest_create_job_of_monthly_meals_id === null}
            />
          )}
          <ModalMUI handleClose={handleClose} open={open} width={"60%"}>
            <MenuEvaluation insufficient={insufficient} menuId={menuId ?? ''} />
          </ModalMUI>
          <UpdateButton
            text={"制約条件編集"}
            onPush={() => {
              navigate(`/menu/${menuId}/conditions`);
            }}
          />
          <MUIButton
            text={"献立を生成する"}
            onPush={(e) => handleClickGenerateMenu(e)}
            disabled={disabledSubmit}
          />
        </Grid>
      </Grid>
      <Grid marginTop={3} sx={{ display: "flex", justifyContent: "flex-end"}}>
        { menu &&
          <UpdateButton
            text={"献立生成結果のダウンロード"}
            onPush={() => handleClickDownload(menu.latest_create_job_of_monthly_meals_id, menu.name, dateStr)}
            disabled={menu.latest_create_job_of_monthly_meals_id === null || isDownloading}
          />
        }
      </Grid>
      { isDownloading &&  (
        <Grid width={"100%"} marginTop={3}>
          <LinearProgress color="inherit"/>
        </Grid>
      )}
      { isDownloadSuccess && (
        <Alert severity="success" sx={{ margin: 2 }}>ダウンロードが完了しました</Alert>
      )}

      <Grid marginTop={3} sx={{ display: "flex", justifyContent: "flex-end"}}>
        { menu &&
          <UpdateButton
            text={"後処理（画像作成・csv作成）の再作成"}
            onPush={() => handleClickPostProcess(menu.latest_create_job_of_monthly_meals_id)}
            disabled={menu.latest_create_job_of_monthly_meals_id === null || isDownloading}
          />
        }
      </Grid>

      <Grid container sx={{ paddingX: (theme) => theme.spacing(3)}}>
        <Grid item sx={{ marginY: (theme) => theme.spacing(6)}}>
          {
            (() => {
              if (menu?.status === MenuJobStatusStr.NoResult) {
                return <Alert severity="warning">ご指定の条件では、献立を生成できませんでした。条件を変更して再度献立を生成してください。</Alert>
              } else if (menu?.status === MenuJobStatusStr.Error) {
                return <Alert severity="error">システムエラーが発生しました。システム管理者に連絡してください。</Alert>
              } else if (menu?.status === MenuJobStatusStr.Processing) {
                return <Alert severity="info"><CircularProgress size="1.2rem" />献立を生成しています。</Alert>
              }
            })()
          }
          <br/>
          { menu && isJobNotFound === false && (
            <Grid sx={{display: "flex", columnGap: (theme) => theme.spacing(6) }}>
              <Input type="text" variant="filled" formTitle={"朝食平均価格"} value={calPriceAvg(menu?.average_prices.breakfast).toString()} disabled />
              <Input type="text" variant="filled" formTitle={"昼食平均価格"} value={calPriceAvg(menu?.average_prices.lunch).toString()} disabled />
              <Input type="text" variant="filled" formTitle={"夕食平均価格"} value={calPriceAvg(menu?.average_prices.dinner).toString()} disabled />
            </Grid>
          )}
        </Grid>
      </Grid>
      
      { isJobNotFound ? (
        <Box sx={{ display: "flex", fontWeight: "bold", justifyContent: "center", marginY: (theme) => theme.spacing(8)}}>
          献立が存在しません。「献立を生成する」を実行してください。
        </Box> 
      ): (
        <MenuView 
          menuId={menuId} 
          menuForMonth={menuForMonth} 
        />
      )}
    </>
  );
});
