import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline/CssBaseline";
import AppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import { FC, memo } from "react";
import { AppSideBar } from "./Sidebar";
import { useAuthContext } from "../../organisms/AuthProvider";
import { useSideBarOpen, sideBarContext } from "./state";
import { Outlet } from "react-router-dom";



export const AppTemplate: FC = memo(() => {
  const openCtx = useSideBarOpen();
  const currentUser = useAuthContext().user;

  return (
    <>
      <CssBaseline />

          {currentUser !== null ? (
            <AppBar
              elevation={0}
              position="static"
              color="inherit"
              sx={{
                borderBottom: "solid 1px #000",
                boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
              }}
            >
              <Toolbar>
                  <IconButton
                    data-testid={"sidebar-open-button"}
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => openCtx.setIsOpen(true)}
                    edge="start"
                  >
                    <MenuIcon color="secondary" />
                  </IconButton>
                  <Box
                    component={"img"}
                    src={`${process.env.PUBLIC_URL}/header_logo.png`}
                    alt="Logo"
                    sx={{ marginLeft: (theme) => theme.spacing(3) }}
                  />
                  <div style={{ flexGrow: 1 }} />
                  <Typography sx={{ justifyContent: "flex-end", fontWeight: "bold" }}>
                    {currentUser?.email}
                  </Typography>
                </Toolbar>
            </AppBar>
          ) : ( <></> )}


      <sideBarContext.Provider value={openCtx}>
        <AppSideBar />
      </sideBarContext.Provider>

      <Grid sx={{ padding: (theme) => theme.spacing(6) }}>
        <Outlet />
      </Grid>
    </>
  );
});
