import { ChangeEvent, DragEvent, useRef, useState } from "react";
import { usePost } from "../../hooks/usePost";
import { APIPathPostDishImgMaster } from "../../endpoint";
import { useSnackbar } from "../../components/organisms/SnackProvider";

export const usePostDishImages = () => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const { showSnackbar } = useSnackbar()

  const onDrop = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault();

    const files = Array.from(e.dataTransfer.files);
    const invalidFiles = validateFiles(files);

    if (invalidFiles.length > 0) {
      const invalidFileNames = invalidFiles.map(file => file.name).join(', ');
      showSnackbar(`無効なファイル名が存在します：${invalidFileNames}`, 'error');
    } else {
      setSelectedFiles(files);
      postFile(files);
    }
  }
  const onDragOver = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
  }

  const handleButtonClick = () => {
    inputRef.current?.click();
  }
  const onFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const files = Array.from(event.target.files || []);

    const invalidFiles = validateFiles(files);

    if (invalidFiles.length > 0) {
      const invalidFileNames = invalidFiles.map(file => file.name).join(', ');
      showSnackbar(`無効なファイル名が存在します：\n
        ${invalidFileNames}`, 'error');
    } else {
      setSelectedFiles(files);
      postFile(files);
      event.target.value = ''; 
    }
  };

  const [ doPost, isLoading ] = usePost({
    method: 'post',
    url: APIPathPostDishImgMaster,
  })

  const isValidFileName = (filename: string): boolean => {
    const pattern = /^\d{8}\.jpg$/;
    return pattern.test(filename);
  }
  const validateFiles = (files: File[]): File[] => {
    return files.filter(file => !isValidFileName(file.name));
  }

  const postFile = async (files: File[]) => {
    const formData = new FormData();
    files.forEach((file, index) => {
      formData.append("files", file);
    });

    doPost({
      params: formData,
      onSuccess: () => {
          showSnackbar(`データの保存が完了しました。`, 'success');
      },
      onError: (err) => {
          showSnackbar(`データの保存に失敗しました。`, "error");
      }
    })
  }

  return {
    selectedFiles,
    isLoading,
    inputRef,
    onDrop,
    onDragOver,
    handleButtonClick,
    onFileChange,
  }
}