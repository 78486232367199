import {FC} from "react";
import {MenuForMonth} from "../../../../dataModel/menu/MenuForMonth";
import {MenuForDay} from "../../../../dataModel/menu/MenuForDay";
import {BlankMenuOfDay} from "./menuOfDay/blank";
import {format} from "../../../../util/date";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {LinkButton} from "../../../atoms/linkButton";
import {MenuCard} from "./menuCard";

export const LineOfMenu: FC<{
  page: number;
  menuData: MenuForMonth;
  menuId: string | undefined;
}> = ({page, menuData, menuId}) => {
  const weeks = ["日", "月", "火", "水", "木", "金", "土"];

  const lineItem = menuData.weeks[page - 1].map((menuOfDay: MenuForDay, idx: number) => {
    if (typeof menuOfDay === "string") {
      return <BlankMenuOfDay key={idx + page}/>;
    } else {
      const targetDay = format(menuOfDay.targetDay, "YYYY-MM-DD");

      return (
        <Grid container item sx={{flexDirection: "column", width: "fit-content",}} key={idx + page}>
          <Grid display="flex" justifyContent="center" bgcolor={"white"} sx={{borderRadius: 20, marginBottom: 2}}>
            <Typography sx={{textAlign: "center", marginY: (theme) => theme.spacing(2), fontWeight: "bold",}}>
              {`${menuOfDay.targetDay.getDate()}日 (${weeks[menuOfDay.weekOfDay]})`}
            </Typography>
            <Box sx={{marginLeft: 2, marginTop: 1.2}}>
              <LinkButton url={`/menu/${menuId}/detail/${targetDay}`} text={"献立詳細"}/>
            </Box>
          </Grid>

          <Grid
            sx={{
              border: "solid 0.5px #000",
              borderRadius: "8px",
              minWidth: "200px",
              padding: "0.8vw",
              justifyContent: "center",
              height: "1290px",
              boxShadow: 10,
            }}
          >
            <MenuCard
              menu={menuOfDay.breakfast}
              targetDay={targetDay}
              menuId={menuId}
            />
            <MenuCard
              menu={menuOfDay.lunch}
              targetDay={targetDay}
              menuId={menuId}
            />
            <MenuCard
              menu={menuOfDay.dinner}
              targetDay={targetDay}
              menuId={menuId}
            />
          </Grid>
        </Grid>
      );
    }
  });

  return <>{lineItem}</>;
};
