import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { FC } from "react";

type Props = {
  onPush: () => void;
  text: string;
  disabled?: boolean;
};

export const UpdateButton: FC<Props> = ({ onPush, text, disabled = false }) => {
  return (
    <Button
      variant="outlined"
      onClick={() => onPush()}
      sx={{
        backgroundColor: (theme) => theme.palette.primary.main,
        border: "solid black 1px",
        "&.Mui-disabled": {
          backgroundColor: "rgba(0, 0, 0, 0.12)",
        },
        "&.Mui-disabled p": {
          color: "rgba(0, 0, 0, 0.26)",
        },
      }}
      disabled={disabled}
    >
      <Typography sx={{ color: (theme) => theme.palette.primary.contrastText }}>{text}</Typography>
    </Button>
  );
};
