import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { FC } from "react";

export const BlankMenuOfDay: FC = () => {
  return (
    <Grid
      container
      item
      sx={{
        flexDirection: "column",
        width: "fit-content",
      }}
    >
      <Grid
        sx={{
          // marginY: (theme) => theme.spacing(3.5),
        }}
      />
      <Grid
        container
        sx={{
          border: "solid 0.5px #000",
          borderRadius: "8px",
          minWidth: "200px",
          padding: "1vw",
          justifyContent: "center",
          height: "1290px",
          boxShadow: 4,
          marginY: (theme) => theme.spacing(9),
        }}
      >
        <Typography sx={{ display: "flex", textAlign: "center", alignItems: "center", fontWeight: "bold",}}>
          生成対象外
        </Typography>
      </Grid>
    </Grid>
  );
};
