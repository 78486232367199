import TableCell from "@mui/material/TableCell";
import Grid from "@mui/material/Grid";
import { memo, FC, useCallback } from "react";
import { usePost } from "../../../../hooks/usePost";
import { DeleteButton } from "../../../atoms/deleteButton";
import { LinkButton } from "../../../atoms/linkButton";
import { useSnackbar } from "../../SnackProvider";

type Props = {
  membershipTypeId: string;
  menuId: string;
};

export const MenuVariationsActionCell: FC<Props> = memo(({ membershipTypeId, menuId}) => {
  const { showSnackbar } = useSnackbar();

  const [ doPost, isLoading ] = usePost({
    method: 'delete',
    url: `/menu/${menuId}`,
  })


  const Delete = useCallback(() => {
    doPost({
      onSuccess: () => {
        window.location.reload();
        showSnackbar("削除しました", "success");
      },
      onError: (err) => {
        showSnackbar(`削除に失敗しました: ${err.message}`, "error");
      }
    })
  }, [membershipTypeId, isLoading]);

  return (
    <TableCell
      variant="body"
      padding={"normal"}
      sx={{
        textAlign: "center",
      }}
    >
      {/* 編集と削除がある */}
      <Grid
        sx={{
          display: "flex",
          flexWrap: "nowrap",
          justifyContent: "center",
          columnGap: (theme) => theme.spacing(3),
        }}
      >
        <LinkButton text={"献立管理"} url={`/menu/${menuId}`} />
        <DeleteButton
          text={"削除する"}
          onPush={() => Delete()}
        />
      </Grid>
    </TableCell>
  );
});
